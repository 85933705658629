import React from 'react'
import '../../estilos/adecuacion.css'
import daac1 from '../../../images/daac/uno.jpg'
import daac2 from '../../../images/daac/dos.jpg'

export const Daac = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Declaración Ambiental de Actividades en Curso (DAAC)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La Declaración Ambiental de Actividades en Curso (DAAC) en el sector agrícola es un informe que proporciona una descripción detallada de las actividades agrícolas en curso, así como una evaluación de sus posibles impactos ambientales y las medidas propuestas o implementadas para mitigar dichos impactos. Algunos de los elementos comunes que se pueden encontrar en una DAAC son:
            </h2>
           <li>Descripción de las actividades agrícolas: Detalles sobre los cultivos cultivados, la ganadería mantenida, los métodos de cultivo o cría utilizados y cualquier otra actividad relacionada con la agricultura.</li>
           <li>Ubicación y extensión: Información sobre la ubicación exacta de las actividades agrícolas y el tamaño de las áreas involucradas.</li>
           <li>Recursos naturales afectados: Identificación de los recursos naturales potencialmente afectados por las actividades agrícolas, como suelo, agua, biodiversidad, paisaje, etc.</li>
           <li>Impactos ambientales potenciales: Evaluación de los posibles efectos adversos de las actividades agrícolas en el medio ambiente, como erosión del suelo, contaminación del agua, pérdida de hábitat, emisiones de gases de efecto invernadero, entre otros.</li>
           <li>Medidas de mitigación: Descripción de las medidas planificadas o implementadas para reducir o evitar los impactos ambientales negativos, como la implementación de prácticas agrícolas sostenibles, el uso de tecnologías más limpias, la protección de áreas sensibles, entre otras.</li>
           <li>Plan de seguimiento y monitoreo: Detalles sobre cómo se llevará a cabo el seguimiento de los impactos ambientales y la efectividad de las medidas de mitigación a lo largo del tiempo.</li>
          </ul>
          <div className="div-adecuacion">
            <img className="diresa-img"  src={daac1} alt="Foto DAAC consultingsel S.R.L" />
            <img className="diresa-img2"  src={daac2} alt="Foto DAAC consultingsel S.R.L" />
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
