import React from 'react'
import '../../estilos/adecuacion.css'

export const Daa = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Declaración de Adecuación Ambiental (DAA)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La Declaración de Adecuación Ambiental (DAA)  es un documento formal que se presenta a las autoridades competentes como parte del proceso de evaluación ambiental de proyectos agrícolas. Esta declaración proporciona información detallada sobre cómo un proyecto agrícola planeado cumplirá con las regulaciones y estándares ambientales establecidos, así como cómo mitigará cualquier impacto negativo en el medio ambiente. Algunos de los aspectos que se pueden incluir en una Declaración de Adecuación Ambiental en el sector agrícola son:
            </h2>
            <li>Descripción del proyecto agrícola: Detalles sobre la naturaleza y el alcance del proyecto agrícola, incluidos los cultivos que se plantarán, las prácticas agrícolas que se utilizarán y cualquier infraestructura asociada.</li>
            <li>Análisis de impacto ambiental: Evaluación de los posibles impactos ambientales del proyecto, como la contaminación del suelo, el agua y el aire, la pérdida de biodiversidad, la erosión del suelo, entre otros.</li>
            <li>Medidas de mitigación: Descripción de las medidas propuestas para reducir o evitar los impactos ambientales negativos del proyecto. Esto puede incluir prácticas agrícolas sostenibles, tecnologías de conservación del suelo y del agua, gestión de residuos, entre otras.</li>
            <li>Cumplimiento normativo: Explicación de cómo el proyecto cumplirá con todas las regulaciones y normativas ambientales locales, regionales y nacionales aplicables.</li>
            <li>Monitoreo y seguimiento: Detalles sobre cómo se llevará a cabo el monitoreo ambiental durante la implementación del proyecto y cómo se informará regularmente sobre los resultados del monitoreo a las autoridades competentes.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
