import React from 'react'
import './estilos/clientes.css'


import achapi from '../images/clientes/chapi.png';
import riachuelo from '../images/clientes/riachuelo.png';
import sika from '../images/clientes/laran.png';
import aib from '../images/clientes/aib.png';
import exfru from '../images/clientes/exfru.png';
import fruchincha from '../images/clientes/fruchincha.png';
import donricardo from '../images/clientes/donricardo.png';
import sunfruits from '../images/clientes/s.png';
import camposur from '../images/clientes/camposur.png';
import agrokasa from '../images/clientes/agrokasa.png';
import bland from '../images/clientes/bland.png';
import padual from '../images/clientes/padual.jpg';
import Challampampa from '../images/clientes/fchallapampa.png';
import euro from '../images/clientes/euro.png';
import cazul from '../images/clientes/cazul.png';
import nbp from '../images/clientes/nbp.png';
import milagrito from '../images/clientes/milagrito.png';
import copacabana from '../images/clientes/copacabana.gif';
import laureles from '../images/clientes/laureles.png';
import floriblanca from '../images/clientes/floriblanca.png';
import queirolo from '../images/clientes/queirolo.png';
import villacuri from '../images/clientes/villacuri.png';
import Safco from '../images/clientes/Safco.png';
import global from '../images/clientes/agro.png';
import manuel from '../images/clientes/manuel.png';
import mineria from '../images/clientes/mineria.png';
import casa from '../images/clientes/casa.png';
import guerrero from '../images/clientes/guerreo.png';
import galactica from '../images/clientes/galactica.png';
import guili from '../images/clientes/guili.png';
import venta from '../images/clientes/laventa.png';
import kanay from '../images/clientes/kanay.png';
import donluis from '../images/clientes/donluis.png';
import vra from '../images/clientes/vra.png';
import apropisco from '../images/clientes/apropisco.png';
import gamuco from '../images/clientes/gamuco.jpg';
import icafruta from '../images/clientes/icafruta.jpg';
import vanguard from '../images/clientes/vanguard.jpg';
import vizcar from '../images/clientes/vizcar.jpg';
import icapacif from '../images/clientes/icapacif.jpg';
import golden from '../images/clientes/golden.jpg';
import santafe from '../images/clientes/santafe.jpg';
import canelas from '../images/clientes/canelas.png';
import empaques from '../images/clientes/empaques.jpg';
import esperanza from '../images/clientes/esperanza.jpg';
import jaime from '../images/clientes/jaime.jpg';
import maria from '../images/clientes/maria.jpg';
import delsur from '../images/clientes/delsur.jpg';
import mamatembo from '../images/clientes/mamatembo.jpg';
import fernagro from '../images/clientes/fernagro.jpg';
import ecovinal from '../images/clientes/ecovinal.jpg';
import miranda from '../images/clientes/miranda.png';
import medanos from '../images/clientes/medanos.jpg';
import blue from '../images/clientes/blue.jpg';
import fibraforte from '../images/clientes/fibraforte.jpg';
import losandes from '../images/clientes/losandes.jpg';
import huamani from '../images/clientes/huamani.jpg';
import polvos from '../images/clientes/polvos.jpg';
import cantera from '../images/clientes/cantera.jpg';
import agroexportica from '../images/clientes/agroexportica.jpg';
import agrolatina from '../images/clientes/agrolatina.jpg';
import gmh from '../images/clientes/gmh.jpg';
import ingenio from '../images/clientes/ingenio.png';
import packing from '../images/clientes/packing.jpg';
import morcar from '../images/clientes/morcar.jpg';
import junta from '../images/clientes/junta.jpg';
import ingenieria from '../images/clientes/ingenieria.jpg';
import procesos from '../images/clientes/procesos.jpg';
import qsi from '../images/clientes/qsi.jpg';
import ruag from '../images/clientes/ruag.jpg';
import gobierno from '../images/clientes/gobierno.png';
import serfi from '../images/clientes/serfi.jpg';
import sociedad from '../images/clientes/sociedad.jpg';
import urbana from '../images/clientes/urbana.jpg';

import camara from '../images/clientes/camara.png';
import car from '../images/clientes/car.jpg';


export const Clientes = () => {
  
  return (
    <>
    <section className='section-clientes'>
      <div className="titulo">
        <h1>Nuestros Aliados</h1>
      </div>
      <div className="socios--">
          <div className="empresa">
            <img className="img-clientes" src={achapi} alt="Agrícola Chapi S.A.C." />
            <p>Agrícola Chapi S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={riachuelo} alt="Agrícola Riachuelo S.A.C." />
            <p>Agrícola Riachuelo S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={sika} alt="Procesadora Laran S.A.C." />
            <p>Procesadora Laran S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={aib} alt="Agroindustrias Aib S.A." />
            <p>Agroindustrias Aib S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={exfru} alt="Exportadora Frutícola del Sur S.A." />
            <p>Exportadora Frutícola del Sur S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={fruchincha} alt="Corporación Frutícola de Chincha S.A." />
            <p>Corporación Frutícola de Chincha S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={donricardo} alt="Agrícola Don Ricardo S.A.C." />
            <p>Agrícola Don Ricardo S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={sunfruits} alt="Sun Fruits Exports S.A." />
            <p>Sun Fruits Exports S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={camposur} alt="Campos del Sur S.A." />
          <p>Campos del Sur S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={agrokasa} alt="Sociedad agrícola Drokasa S.A." />
            <p>Sociedad agrícola Drokasa S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={bland} alt="Bland Farms (Perú) S.A.C" />
            <p>Bland Farms (Perú) S.A.C</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={vanguard} alt="Pacific Farms S.A.C" />
            <p>Pacific Farms S.A.C</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={Challampampa} alt="Agrícola Challampampa S.A.C." />
            <p>Agrícola Challampampa S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={euro} alt="Euro S.A." />
            <p>Euro S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={cazul} alt="Conservas y congelados cerro azul S.R.L." />
            <p>Conservas y congelados cerro azul S.R.L.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={nbp} alt="North Bay Produce S.A." />
            <p>North Bay Produce S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={milagrito} alt="Corporación agrícola Milagritos S.A.C." />
            <p>Corporación agrícola Milagritos S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={copacabana} alt="Agrícola Copacabana de Chincha S.A." />
            <p>Agrícola Copacabana de Chincha S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={laureles} alt="Los Laureles De Villacuri S.A.C." />
            <p>Los Laureles De Villacuri S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={floriblanca} alt="Floridablanca S.A.C." />
            <p>Floridablanca S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={padual} alt="Fundo el Padua S.A.C." />
            <p>Fundo el Padua S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={vizcar} alt="Import Eportvizcar S.A." />
            <p>Import Eportvizcar S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={queirolo} alt="Agrícola Santiago Queirolo S.A.C." />
            <p>Agrícola Santiago Queirolo S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={icafruta} alt="Ica fruta S.A" />
            <p>Ica fruta S.A</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={icapacif} alt="Ica Pacific S.A" />
            <p>Ica Pacific S.A</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={villacuri} alt="Los Olivos de Villacuri S.A.C." />
            <p>Los Olivos de Villacuri S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={golden} alt="Ccori Golden Company S.A.C." />
            <p>Ccori Golden Company S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={Safco} alt="Agrícola Safco Perú S.A." />
            <p>Agrícola Safco Perú S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={santafe} alt="Compañía Agroindustrial Santa Fe de Lanchas S.A.C." />
            <p>Compañía Agroindustrial Santa Fe de Lanchas S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={global} alt="Global Agro Perú S.A.C." />
            <p>Global Agro Perú S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={delsur} alt="Condominios del Sur S.A.C." />
            <p>Condominios del Sur S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={manuel} alt="Manuelita FYH S.A.C" />
            <p>Manuelita FYH S.A.C</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={mineria} alt="Minera Colibri S.A.C." />
            <p>Minera Colibri S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={casa} alt="Casa Chica S.A.C." />
            <p>Casa Chica S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={guerrero} alt="Agrícola la Guerrero S.A.C." />
            <p>Agrícola la Guerrero S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={jaime} alt="Agrícola Jaime Javier S.A.C." />
            <p>Agrícola Jaime Javier S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={maria} alt="Agrícola María Angélica S.A.C." />
            <p>Agrícola María Angélica S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={esperanza} alt="Corporación Esperanza del Inka S.A.C." />
            <p>Corporación Esperanza del Inka S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={galactica} alt="Galáctica Sociedad agrícola S.A.C." />
            <p>Galáctica Sociedad agrícola S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={gamuco} alt="Agrícola Gamuco S.A.C." />
            <p>Agrícola Gamuco S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={canelas} alt="Agrícola Ganadera las Canelas S.A.C." />
            <p>Agrícola Ganadera las Canelas S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={guili} alt="Agrícola Guilo S.A.C." />
            <p>Agrícola Guili S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={venta} alt="Agrícola la Venta S.A." />
            <p>Agrícola la Venta S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={empaques} alt="Agro empaques Safco S.A." />
            <p>Agro empaques Safco S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={mamatembo} alt="Agropecuaria Mamatembo S.A.C." />
            <p>Agropecuaria Mamatembo S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={fernagro} alt="Fernagro S.A.C." />
            <p>Fernagro S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={ecovinal} alt="Grupo Ecovinal S.A.C." />
            <p>Grupo Ecovinal S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={kanay} alt="Kanay S.A.C." />
            <p>Kanay S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={donluis} alt="Sociedad agrícola Don Luis S.A." />
            <p>Sociedad agrícola Don Luis S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={vra} alt="Desarrollo sociedad y medio ambiente VRA S.A.C." />
            <p>Desarrollo sociedad y medio ambiente VRA S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={apropisco} alt="Apropisco S.A.C." />
            <p>Apropisco S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={miranda} alt="Agrícola Miranda S.A.C." />
            <p>Agrícola Miranda S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={medanos} alt="Agrícola Miranda S.A.C." />
            <p>Agrícola los Médanos S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={blue} alt="Agrícola Blue Gold S.A.C." />
            <p>Agrícola Blue Gold S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={fibraforte} alt="Fibraforte S.A." />
            <p>Fibraforte S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={losandes} alt="Agrícola los Adenes S.A.C." />
            <p>Agrícola los Adenes S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={huamani} alt="Agroindustrial Huamani S.A.C." />
            <p>Agroindustrial Huamani S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={polvos} alt="Asociación de propietarios del centro comercial polvos azules-Ica" />
            <p>Asociación de propietarios del centro comercial polvos azules-Ica</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={cantera} alt="Cantera Río Chincha S.A.C." />
            <p>Cantera Río Chincha S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={agroexportica} alt="Corporación Agroexportica S.A.C." />
            <p>Corporación Agroexportica S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={agrolatina} alt="Corporación Agrolatina S.A.C." />
            <p>Corporación Agrolatina S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={gmh} alt="GMH Berries S.A.C." />
            <p>GMH Berries S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={ingenio} alt="Municipalidad Distrital de el Ingenio" />
            <p>Municipalidad Distrital de el Ingenio</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={packing} alt="Pacific Packing Group S.A.C." />
            <p>Pacific Packing Group S.A.C.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={morcar} alt="Morcar Group E.I.R.L" />
            <p>Morcar Group E.I.R.L</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={junta} alt="Junta de usuarios del sector hidráulico menor Ica-clase B" />
            <p>Junta de usuarios del sector hidráulico menor Ica-clase B</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={ingenieria} alt="Ingeniería de construcción y mantenimiento S.A.C. Contratistas generales" />
            <p>Ingeniería de construcción y mantenimiento S.A.C. Contratistas generales</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={procesos} alt="Procesos Agroindustriales sociedad anónima" />
            <p>Procesos Agroindustriales sociedad anónima</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={qsi} alt="QSI Perú S.A." />
            <p>QSI Perú S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={ruag} alt="RUAG S.R.L." />
            <p>RUAG S.R.L.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={gobierno} alt="Gobierno Regional de Ica" />
            <p>Gobierno Regional de Ica</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={serfi} alt="Servicios y formulaciones industriales S.A." />
            <p>Servicios y formulaciones industriales S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={sociedad} alt="Sociedad Agrícola San Agustín de zaña S.A." />
            <p>Sociedad Agrícola San Agustín de zaña S.A.</p>
          </div>
          <div className="empresa">
            <img className="img-clientes" src={urbana} alt="Urbana Consultores de gestión integral E.I.R.L" />
            <p>Urbana Consultores de gestión integral E.I.R.L</p>
          </div>
      </div>
      <div className="titulo">
        <h1>Nuestros Asociados</h1>
      </div>
      <div className="asocidado">
          <div className="empresa">
            <img className="img-asociado" src={camara} alt="Cámara de Comercio de Ica"/>
            <p>Cámara de Comercio de Ica</p>
          </div>
          <div className="empresa">
            <img className="img-asociado" src={car} alt="Comisión Ambiental Regional de Ica" />
            <p>Comisión Ambiental Regional de Ica</p>
          </div>
      </div>
    </section> 
    </>
  )
}
