import React from 'react'
import '../../estilos/suelo.css'
import suelo from '../../../images/Msuelo/suelo.jpg'
import suelo2 from '../../../images/Msuelo/suelo2.jpg'
import suelo3 from '../../../images/Msuelo/suelo3.jpg'


export const Monitoreosuelo = () => {
  return (
    <>
      <section className="suelo">
        <div className="titulo-suelo">
          <h1>Monitoreo Suelo</h1>
        </div>
        <div className="cuerpo-suelo">
          <div className="list-suelo">
            <ul className="info-2">
              <h1 className="suelo-ti">
              Reglamento de Estándares Nacionales de Calidad Ambiental (ECA) para Suelo, aprobado por el D.S. N° 011-2017-MINAM
              </h1>
              <h2 className="suelo-ti2">
              El monitoreo ambiental de la calidad del suelo en el sector agrícola es esencial para evaluar y gestionar el impacto de las actividades agrícolas en la salud del suelo y el medio ambiente. Aquí presentamos los pasos y consideraciones fundamentales para llevar a cabo un monitoreo efectivo:
              </h2>
              <li>Identificación de parámetros a monitorear: Los parámetros comunes que se monitorean en la calidad del suelo incluyen la textura, la estructura, el pH, la materia orgánica, F1, F2 y F3, la concentración de nutrientes (nitrógeno, fósforo, potasio), la presencia de metales pesados y la actividad microbiana. </li>
              <li>Selección de ubicaciones de muestreo: Debes seleccionar sitios de muestreo representativos de las diferentes actividades agrícolas y condiciones del suelo en tu área de estudio. Esto puede incluir áreas de cultivo intensivo, pastizales, áreas degradadas y zonas no perturbadas como puntos de referencia.</li>
              <li>Técnicas de muestreo: Elige las técnicas de muestreo adecuadas para obtener muestras representativas del suelo. Esto puede incluir la recopilación de muestras compuestas de múltiples lugares dentro de un área, la profundización de perfiles de suelo para evaluar la textura y estructura, y la toma de muestras en diferentes épocas del año para evaluar cambios estacionales.</li>
              <li>Análisis de muestras: Una vez recolectadas las muestras, estas deben ser analizadas en laboratorio para determinar los parámetros de interés. Es importante utilizar métodos de análisis acreditados y seguir procedimientos estandarizados para garantizar la precisión y la reproducibilidad de los resultados.</li>
              <li>Interpretación de resultados: Los resultados del análisis de suelo deben interpretarse en el contexto de los objetivos del monitoreo y las condiciones locales. Esto puede implicar comparar los valores obtenidos con estándares de calidad del suelo, evaluar tendencias a lo largo del tiempo y considerar la influencia de factores como el tipo de suelo, el uso de la tierra y las prácticas agrícolas.</li>
              <li>Acciones correctivas y gestión: Si se identifican problemas de calidad del suelo, es importante tomar medidas correctivas para mitigar los impactos negativos. Esto puede incluir la aplicación de enmiendas para corregir deficiencias nutricionales, la implementación de prácticas de conservación del suelo para reducir la erosión y la contaminación, y la adopción de sistemas de manejo agrícola sostenible.</li>
            </ul>
            <div className="div-suelo">
              <img className="suelo-img"  src={suelo} alt="Foto monitoreo suelo consultingsel S.R.L" />
              <img className="suelo-img2"  src={suelo2} alt="Foto monitoreo suelo consultingsel S.R.L" />
              <img className="suelo-img2"  src={suelo3} alt="Foto monitoreo suelo consultingsel S.R.L" />

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
