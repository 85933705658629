import React from 'react'
import '../../estilos/digesa.css'
// import consumohumano from '../../../../images/consumohumano/consumo.jpg';
// import consumohumano2 from '../../../../images/consumohumano/consumo2.jpg';
export const Digesa = () => {
  return (
    <>
      <section className="digesa">
        <div className="titulo-digesa">
          <h1>Exp. digesa</h1>
        </div>
        <div className="cuerpo-digesa">
          <div className="list-digesa">
            <ul className="info-9">
              <h1 className="digesa-ti">
                AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E
                INFILTRACION EN EL TERRENO – DIGESA (DESA)
              </h1>
              <h2 className="digesa-ti2">
                La Dirección General de Salud Ambiental e Inocuidad Alimentaria
                (DIGESA) es la autoridad competente en Perú responsable de
                regular y supervisar la seguridad sanitaria en diversos ámbitos,
                incluyendo la calidad del agua para consumo humano, la inocuidad
                de los alimentos y la gestión de residuos sólidos, entre otros.
                Para obtener una autorización sanitaria de DIGESA en Perú,
                generalmente se deben seguir ciertos pasos y cumplir con ciertos
                requisitos, dependiendo del tipo de actividad o servicio que se
                esté buscando autorizar. A continuación, te presento algunos
                aspectos generales relacionados con la obtención de una
                autorización sanitaria en Perú:
              </h2>
              <li>
              Identificación de la actividad: Lo primero es identificar la actividad específica para la cual se necesita la autorización sanitaria. Esto puede ser la operación de un establecimiento de alimentos, la instalación de un sistema de tratamiento de aguas residuales, la producción o importación de productos químicos, entre otros.

              </li>
              <li>
              Requisitos específicos: Cada tipo de actividad tendrá requisitos específicos que deben cumplirse para obtener la autorización sanitaria. Estos requisitos pueden incluir la presentación de documentos técnicos, estudios de impacto ambiental, certificados de calidad, planes de gestión de residuos, entre otros.

              </li>
              <li>
              Presentación de solicitud: Una vez que se han identificado los requisitos, se debe presentar una solicitud de autorización sanitaria ante DIGESA. Esto puede implicar completar formularios específicos y adjuntar la documentación requerida.

              </li>
              <li>
              Evaluación y trámite: DIGESA llevará a cabo una evaluación de la solicitud y de la documentación presentada. Esto puede incluir inspecciones en el lugar, revisión de documentos técnicos y análisis de muestras, según sea necesario. El tiempo necesario para el trámite puede variar dependiendo de la complejidad de la solicitud y de la carga de trabajo de la autoridad competente.

              </li>
              <li>
              Emisión de la autorización: Si se determina que se cumplen todos los requisitos y que la actividad cumple con las normativas sanitarias aplicables, DIGESA emitirá la autorización sanitaria correspondiente. Esta autorización puede tener una validez limitada en el tiempo y puede estar sujeta a condiciones específicas que deben cumplirse.
              </li>
            </ul>
            <div className="div-digesa">
              {/* <img className="digesa-img"  src={digesa} alt="Foto digesa consultingsel S.R.L" />
            <img className="digesa-img2"  src={digesa2} alt="Foto digesa consultingsel S.R.L" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

