import React from 'react'
import '../../estilos/biodigestores.css'
import biodigestores from '../../../images/biodigestores/biodigestores.jpg';
import biodigestores2 from '../../../images/biodigestores/biodigestores2.jpg';

export const Biodigestores = () => {
  return (
    <>
      <section className="biodigestores">
        <div className="titulo-biodigestores">
          <h1>Biodigestores</h1>
        </div>
        <div className="cuerpo-biodigestores">
          <div className="list-biodigestores">
            <ul className="info-7">
              {/* <h1 className="biodigestores-ti">
              Estándares de Calidad Ambiental para Agua, aprobado por el D.S. N°004-2017-MINAM
              </h1> */}
              <h2 className="biodigestores-ti2">
              Un biodigestor es un sistema que utiliza microorganismos para descomponer materia orgánica en condiciones anaeróbicas (sin oxígeno), produciendo biogás y un residuo orgánico estabilizado llamado digestato. Estos sistemas son especialmente útiles para el tratamiento de desechos orgánicos, como residuos agrícolas, estiércol animal, residuos de alimentos y aguas residuales domésticas.
              Aquí tienes algunas características clave de los biodigestores:
              </h2>
              <li>Funcionamiento: En un biodigestor, la materia orgánica se introduce en un ambiente sin oxígeno, donde es degradada por bacterias anaeróbicas en un proceso conocido como digestión anaeróbica. Durante este proceso, se produce biogás, compuesto principalmente por metano (CH4) y dióxido de carbono (CO2), que puede ser utilizado como fuente de energía.</li>
              <li>Componentes: Un biodigestor típico consta de un tanque hermético o reactor donde ocurre la digestión anaeróbica, así como un sistema de recolección y almacenamiento de biogás y un sistema de separación y almacenamiento del digestato.</li>
              <li>Tipos de biodigestores: Hay varios tipos de biodigestores disponibles, que varían en tamaño, diseño y tecnología. Algunos de los tipos más comunes incluyen los biodigestores de flujo continuo, los biodigestores de flujo discontinuo (batches), los biodigestores tubulares y los biodigestores prefabricados.</li>
              <li>Beneficios ambientales: Los biodigestores ayudan a reducir la contaminación ambiental al tratar los desechos orgánicos de manera segura y producir energía renovable. También ayudan a mitigar el cambio climático al reducir las emisiones de gases de efecto invernadero, especialmente metano, que es un gas de efecto invernadero mucho más potente que el dióxido de carbono.</li>
              <li>Aplicaciones: Los biodigestores son utilizados en una variedad de aplicaciones, desde pequeñas instalaciones domésticas hasta grandes sistemas industriales. Son especialmente comunes en áreas rurales y agrícolas, donde pueden ayudar a gestionar los residuos agrícolas y proporcionar energía renovable a bajo costo.</li>
            </ul>
            <div className="div-biodigestores">
              <img className="biodigestores-img"  src={biodigestores} alt="Foto biodigestores precolador consultingsel S.R.L" />
              <img className="biodigestores-img2"  src={biodigestores2} alt="Foto biodigestores precolador consultingsel S.R.L" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
