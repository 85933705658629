import React from 'react'
import '../estilos/mensajeria.css'
export const Mensajeria = () => {
  return (
    <>
    <div className="cuerpo-mensajeria">
      {/* <h1>Mensajería</h1>
      <p>Tu opinión importa, dejanos saber que te parece nuesta página</p>
        <div className="contenedor-mensajeria">
            <form method="_post">
                <input type="text" name="" id="" placeholder="Name"/>
                <textarea name="" id="" cols="30" rows="10"></textarea>
                <input className="enviar" type="submit" value="Enviar" />
            </form>
        </div> */}
    </div>
    <h1 className="proximamente">Próximamente</h1>
    </>
  )
}
