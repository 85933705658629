import React from 'react'
import  '../../estilos/aire.css'
import aire from '../../../images/Maire/aire.jpg'
import aire2 from '../../../images/Maire/aire2.jpg'


export const Monitoreoaire = () => {
  return (
    <>
      <section className="aire">
        <div className="titulo-aire">
          <h1>Monitoreo Aire</h1>
        </div>
        <div className="cuerpo-aire">
          <div className="list-aire">
            <ul className="info">
              <h1 className="aire-ti">
                Reglamento de Estándares Nacionales de Calidad Ambiental (ECA)
                para Aire, aprobado por el D.S. N° 003-2017-MINAM
              </h1>
              <h2 className="aire-ti2">
                El monitoreo ambiental de la calidad del aire en el sector
                agrícola es fundamental para evaluar y controlar los impactos
                que las actividades agrícolas pueden tener en el medio ambiente
                y en la salud humana. Aquí detallamos algunas consideraciones
                importantes para llevar a cabo un monitoreo efectivo:
              </h2>
              <li>
                Identificación de fuentes de contaminación: Las actividades
                agrícolas pueden contribuir a la contaminación del aire a través
                de emisiones de compuestos orgánicos volátiles (COV), amoníaco
                (NH3), óxidos de nitrógeno (NOx), entre otros. Es importante
                identificar las principales fuentes de contaminación en la zona
                de estudio.
              </li>
              <li>
                Selección de ubicaciones de monitoreo: Debes colocar los
                dispositivos de monitoreo en lugares estratégicos que
                representen diferentes condiciones ambientales y la exposición
                de la población. Esto puede incluir áreas cercanas a campos de
                cultivo, zonas residenciales, carreteras transitadas, etc.
              </li>
              <li>
                Elección de parámetros a medir: Los parámetros comunes que se
                miden en el monitoreo de la calidad del aire incluyen partículas
                en suspensión (PM10, PM2.5), óxidos de nitrógeno (NOx), dióxido
                de azufre (SO2), ozono (O3), monóxido de carbono (CO), entre
                otros
              </li>
              <li>
                Selección de equipos de monitoreo: Debes elegir los dispositivos
                de monitoreo adecuados para cada parámetro que deseas medir.
                Esto puede incluir estaciones automáticas, sensores portátiles,
                o incluso dispositivos de muestreo pasivo, dependiendo de tus
                necesidades y recursos disponibles.
              </li>
              <li>
                Análisis de datos y reporte de resultados: Una vez recopilados
                los datos, es importante analizarlos para identificar
                tendencias, patrones y posibles problemas de calidad del aire en
                la zona de estudio. Los resultados deben ser comunicados de
                manera clara y accesible a las autoridades, comunidades locales
                y otros interesados.
              </li>
              <li>
                Implementación de medidas correctivas: Si se identifican
                problemas de calidad del aire, es importante tomar medidas
                correctivas para mitigar los impactos negativos. Esto puede
                incluir la implementación de prácticas agrícolas más
                sostenibles, el uso de tecnologías más limpias, o la regulación
                de ciertas actividades.
              </li>
            </ul>
            <div className="div">
              <img className="aire-img"  src={aire} alt="Foto monitoreo aire consultingsel S.R.L" />
              <img className="aire-img2" src={aire2} alt="Foto monitoreo aire consultingsel S.R.L" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
