import React from 'react'
import '../../estilos/adecuacion.css'
export const Capacitaciones = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Capacitaciones ambientales</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Las capacitaciones ambientales son programas diseñados para brindar conocimientos, habilidades y conciencia ambiental a los agricultores, trabajadores agrícolas, y otros actores involucrados en actividades relacionadas con la agricultura. Estas capacitaciones tienen como objetivo promover prácticas agrícolas sostenibles y respetuosas con el medio ambiente, así como el cumplimiento de las regulaciones ambientales. Algunos temas comunes abordados en las capacitaciones ambientales en el sector agrícola incluyen:
            </h2>
            <li>Conservación del suelo: Métodos para prevenir la erosión del suelo, mejorar su estructura y fertilidad, y promover prácticas de labranza conservacionista.</li>
            <li>Uso eficiente del agua: Técnicas de riego eficientes, manejo de la irrigación para minimizar el desperdicio de agua y estrategias de conservación del agua.</li>
            <li>Manejo de agroquímicos: Buenas prácticas para el uso seguro y responsable de fertilizantes, pesticidas y herbicidas, incluyendo la dosificación adecuada y la protección de la salud humana y del medio ambiente.</li>
            <li>Biodiversidad y conservación de la naturaleza: Importancia de la diversidad biológica en los sistemas agrícolas, métodos para promover la biodiversidad en las fincas agrícolas y estrategias de conservación de la naturaleza.</li>
            <li>Gestión de residuos: Manejo adecuado de los residuos agrícolas, compostaje, reciclaje y disposición segura de desechos para prevenir la contaminación ambiental.</li>
            <li>Adaptación al cambio climático: Estrategias para enfrentar los impactos del cambio climático en la agricultura, incluyendo prácticas de manejo de cultivos resistentes a la sequía, inundaciones u otros eventos climáticos extremos.</li>
            <li>Cumplimiento de regulaciones ambientales: Conocimiento de las leyes y regulaciones ambientales aplicables a la agricultura, y las responsabilidades de los agricultores en términos de cumplimiento.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
