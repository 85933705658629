import React from 'react'
import '../../estilos/adecuacion.css'
export const Sunafil = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Informes de Letrinas (SUNAFIL)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Un informe de letrinas generalmente se refiere a un documento que proporciona detalles sobre la situación, el estado y la gestión de las instalaciones de saneamiento, específicamente letrinas, en áreas agrícolas o rurales. Estos informes suelen ser parte de evaluaciones más amplias de condiciones de vida, salud pública o programas de desarrollo rural. Los informes de letrinas pueden abordar varios aspectos, incluyendo:
            </h2>
            <li>Cantidad y ubicación de letrinas: Indican el número de letrinas disponibles en un área específica y su distribución geográfica dentro de una comunidad agrícola.</li>
            <li>Condiciones de las letrinas: Describen el estado físico y funcional de las letrinas, incluyendo si están en buen estado de conservación, si tienen fugas, si están bien ventiladas, etc.</li>
            <li>Higiene y saneamiento: Evalúan si las letrinas están siendo utilizadas adecuadamente por la comunidad, si se están limpiando regularmente y si se están adoptando prácticas higiénicas adecuadas.</li>
            <li>Impacto en la salud: Analizan el impacto de las letrinas en la salud pública y la prevención de enfermedades relacionadas con el agua y el saneamiento, como enfermedades transmitidas por el agua o infecciones gastrointestinales.</li>
            <li>Necesidades de mejora: Identifican posibles áreas de mejora en la gestión de letrinas, como la necesidad de reparaciones, la instalación de más letrinas para cubrir la demanda de la población, la promoción de prácticas higiénicas adecuadas, entre otros.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
