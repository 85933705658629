import React from 'react'
import '../../estilos/tanqueseptico.css'
import tanque from '../../../images/tanqueseptico/tanque.jpg';
import tanque2 from '../../../images/tanqueseptico/tanque2.jpg';
import tanque3 from '../../../images/tanqueseptico/tanque3.jpg';

export const Tanqueseptico = () => {
  return (
    <>
      <section className="tanque">
        <div className="titulo-tanque">
          <h1>Tanque Séptico</h1>
        </div>
        <div className="cuerpo-tanque">
          <div className="list-tanque">
            <ul className="info-5">
              {/* <h1 className="tanque-ti">
              Estándares de Calidad Ambiental para Agua, aprobado por el D.S. N°004-2017-MINAM
              </h1> */}
              <h2 className="tanque-ti2">
              Un tanque séptico es un componente fundamental de un sistema de tratamiento de aguas residuales que se utiliza comúnmente en áreas donde no hay acceso a sistemas de alcantarillado municipal. Funciona como un tipo de sistema de tratamiento primario para las aguas residuales domésticas, separando los sólidos y permitiendo la degradación parcial de los materiales orgánicos presentes en el agua residual.
                Aquí hay algunas características clave de un tanque séptico:
              </h2>
              <li>Diseño: Por lo general, un tanque séptico está hecho de materiales duraderos como concreto. Tiene una forma generalmente y está enterrado bajo tierra.</li>
              <li>Funcionamiento: El tanque séptico recibe las aguas residuales provenientes de los desagües de la casa, como los desagües de la cocina, del baño y del lavadero. Dentro del tanque, los sólidos más pesados se hunden al fondo, formando una capa de lodo, mientras que los aceites y grasas flotan en la superficie, formando una capa de escoria. El agua tratada se encuentra en el medio y fluye hacia afuera del tanque hacia un campo de drenaje o una zanja de infiltración.</li>
              <li>Proceso de tratamiento: En el tanque séptico, ocurre un proceso natural de descomposición de los sólidos orgánicos por acción bacteriana. Este proceso reduce la carga orgánica del agua residual, aunque no la trata completamente. Los sólidos se acumulan en el fondo del tanque y deben ser bombeados periódicamente por un servicio de limpieza de tanques sépticos para evitar que obstruyan el sistema.</li>
              <li>Mantenimiento: Para garantizar un funcionamiento eficaz, un tanque séptico requiere un mantenimiento regular. Esto incluye la eliminación periódica de lodos acumulados mediante el bombeo del tanque, así como la inspección y limpieza de los componentes del sistema, como las tuberías de entrada y salida.</li>
              <li>Consideraciones ambientales y de salud: Es importante que un tanque séptico esté diseñado, instalado y mantenido adecuadamente para evitar la contaminación del suelo y las aguas subterráneas, así como para prevenir la propagación de enfermedades transmitidas por el agua. Las normativas locales y nacionales suelen establecer requisitos específicos para el diseño, la instalación y el mantenimiento de los tanques sépticos.</li>
            </ul>
            <div className="div-tanque">
              <img className="tanque-img"  src={tanque} alt="Foto tantque séptico consultingsel S.R.L" />
              <img className="tanque-img2"  src={tanque2} alt="Foto tantque séptico consultingsel S.R.L" />
              <img className="tanque-img2"  src={tanque3} alt="Foto tantque séptico consultingsel S.R.L" />

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
