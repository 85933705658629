import React from 'react'
import '../../estilos/biologico.css'
import biologico from '../../../images/mbiologico/biologico.jpg'
import biologico2 from '../../../images/mbiologico/biologico2.jpg'
import biologico3 from '../../../images/mbiologico/biologico3.jpg'

  
export const Monitoreobiologico = () => {
  return (
    <>
      <section className="biologico">
        <div className="titulo-biologico">
          <h1>Monitoreo biologico</h1>
        </div>
        <div className="cuerpo-biologico">
          <div className="list-biologico">
            <ul className="info-4">
              {/* <h1 className="biologico-ti">
              Estándares Nacionales de Calidad Ambiental para biologico en conformidad al D.S N°085-2003-PCM
              </h1> */}
              <h2 className="biologico-ti2">
              El monitoreo ambiental de la calidad de flora y fauna en el sector agrícola es esencial para evaluar y gestionar los impactos de las actividades agrícolas en los ecosistemas naturales. Aquí te presento algunos pasos y consideraciones importantes para llevar a cabo un monitoreo efectivo:
              </h2>
              <li>Identificación de especies clave: Es importante identificar las especies de flora y fauna más relevantes en el área de estudio, tanto aquellas que pueden verse directamente afectadas por las actividades agrícolas como aquellas que podrían ser indicadores de la salud general del ecosistema.</li>
              <li>Selección de métodos de muestreo: Se deben seleccionar métodos de muestreo apropiados para la recolección de datos sobre la flora y fauna. Esto puede incluir técnicas de muestreo de suelo y vegetación, como transectos y cuadrantes, así como métodos de muestreo de fauna, como trampas, redes de niebla y observación directa.</li>
              <li>Determinación de parámetros a medir: Los parámetros que se pueden medir en el monitoreo de flora y fauna incluyen la diversidad de especies, la abundancia relativa, la distribución espacial, el estado de salud de las plantas, la presencia de especies invasoras y la presencia de enfermedades o signos de estrés.</li>
              <li>Implementación del monitoreo: Se deben llevar a cabo visitas de campo periódicas para recolectar datos sobre la flora y fauna utilizando los métodos seleccionados. Es importante seguir protocolos estandarizados y mantener registros precisos de todas las observaciones y mediciones realizadas.</li>
              <li>Análisis de datos y evaluación de impacto: Una vez recopilados los datos, se deben analizar para evaluar los posibles impactos de las actividades agrícolas en la flora y fauna del área de estudio. Esto puede incluir comparaciones con datos de referencia, análisis de tendencias temporales y evaluación de la correlación entre los cambios en la agricultura y los cambios en la biodiversidad.</li>
              <li>Desarrollo de medidas de conservación y mitigación: Si se identifican impactos negativos en la flora y fauna, es importante desarrollar medidas de conservación y mitigación para reducir estos impactos. Esto puede incluir la implementación de prácticas agrícolas sostenibles, la restauración de hábitats degradados, la creación de corredores biológicos y la protección de áreas de alto valor ecológico.</li>
            </ul>
            <div className="div-biologico">
              <img className="biologico-img"  src={biologico} alt="Foto monitoreo biologico consultingsel S.R.L" />
              <img className="biologico-img2"  src={biologico2} alt="Foto monitoreo biologico consultingsel S.R.L" />
              <img className="biologico-img2"  src={biologico3} alt="Foto monitoreo biologico consultingsel S.R.L" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
