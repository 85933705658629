import React from 'react'
import '../../../estilos/consumohumano.css'
import consumohumano from '../../../../images/consumohumano/consumo.jpg';
import consumohumano2 from '../../../../images/consumohumano/consumo2.jpg';

export const Consumohumano = () => {
  return (
    <>
    <section className="consumohumano">
      <div className="titulo-consumohumano">
        <h1>Consumo Humano</h1>
      </div>
      <div className="cuerpo-consumohumano">
        <div className="list-consumohumano">
          <ul className="info-8">
            <h1 className="consumohumano-ti">
            Reglamento de la Calidad de Agua para Consumo Humano, aprobado por el D.S. N° N°031-2010-SA.
            </h1>
            <h2 className="consumohumano-ti2">
            El monitoreo ambiental de la calidad del agua para consumo humano en el sector agrícola es fundamental para garantizar la seguridad y la salud de las personas que dependen de fuentes de agua potable afectadas por actividades agrícolas. Aquí tienes algunos pasos y consideraciones importantes para llevar a cabo un monitoreo efectivo:
            </h2>
           <li>Identificación de fuentes de agua: Es importante identificar las fuentes de agua utilizadas para consumo humano en áreas agrícolas, como pozos de agua, manantiales, arroyos y ríos. Estas fuentes pueden estar expuestas a contaminantes agrícolas como fertilizantes, pesticidas y desechos animales.</li>
           <li>Selección de parámetros a monitorear: Los parámetros que se deben monitorear en el agua para consumo humano incluyen la presencia de contaminantes químicos como nitratos, pesticidas, metales pesados y productos químicos orgánicos, así como la presencia de contaminantes microbiológicos como bacterias, virus y protozoos patógenos.</li>
           <li>Frecuencia de muestreo: Se debe establecer una frecuencia de muestreo adecuada para capturar las variaciones estacionales y los posibles eventos de contaminación. Esto puede implicar muestrear de manera regular, especialmente después de eventos de lluvia intensa o actividades agrícolas intensivas.</li>
           <li>Técnicas de muestreo y análisis: Debes utilizar técnicas de muestreo y análisis apropiadas para garantizar la precisión y la fiabilidad de los resultados. Esto puede incluir la toma de muestras representativas en diferentes puntos de la fuente de agua y el análisis en laboratorio utilizando métodos acreditados.</li>
           <li>Interpretación de resultados y acción: Una vez analizadas las muestras, debes interpretar los resultados en función de los estándares de calidad del agua y los posibles riesgos para la salud humana. Si se identifican problemas de calidad del agua, es importante tomar medidas correctivas para proteger la salud de las personas que consumen el agua.</li>
           <li>Gestión y mitigación de riesgos: Para prevenir la contaminación del agua potable por actividades agrícolas, es importante implementar prácticas de manejo del suelo y del agua que reduzcan el uso de fertilizantes y pesticidas, promuevan la conservación del suelo y protejan las fuentes de agua potable.</li>
          </ul>
          <div className="div-consumohumano">
            <img className="consumohumano-img"  src={consumohumano} alt="Foto consumohumano consultingsel S.R.L" />
            <img className="consumohumano-img2"  src={consumohumano2} alt="Foto consumohumano consultingsel S.R.L" />
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
