import React from 'react'
import '../estilos/acreditaciones.css'  
import doc1 from '../../documents/doc1.pdf'
import doc2 from '../../documents/doc2.pdf'
import doc3 from '../../documents/doc3.pdf'
import produce from '../../images/produce.png'
import minsa from '../../images/minsa.webp'
import midagri from '../../images/midagri.png'

import doc4 from '../../documents/doc4.pdf'
import doc5 from '../../documents/doc5.pdf'
import doc6 from '../../documents/doc6.pdf'
import doc7 from '../../documents/doc7.pdf'

import doc8 from '../../documents/doc8.pdf'

import doc9 from '../../documents/doc9.pdf'



export const Acreditaciones = () => {
  return (
    <>
    <section className="acreditaciones">
        <div className="acre">
            <h1>Acreditaciones</h1>
        </div>
        <div className="documents">
        <div className="apartados-acre">
    <img className="img-acre" src={produce} alt="Ministerio de la Producción" />
    <iframe id="doc1" src={doc1} title="Documento 1" frameBorder="0" width="100%" height="600"></iframe>
</div>
            <div className="apartados-acre">
                <img className="img-acre" src={minsa} alt="Ministerio de Salud" />
                <iframe id="doc2" src={doc2} title="Documento 2" frameBorder="0"></iframe>
            </div>
            <div className="apartados-acre">
                <img className="img-acre" src={midagri} alt="Ministerio de Desarrollo Agrario y Riego" />
                <iframe id="doc3" src={doc3} title="Documento 3" frameBorder="0"></iframe>
            </div>
        </div>
        <div className="acre">
            <h1>Certificados - INACAL</h1>
        </div>
        <h6 className="low-vol">Certificados Low Vol</h6>
        <div className="low">
            <div className="apartados-acre">
                <iframe id="doc3" src={doc4} title="Documento 4" frameBorder="0"></iframe>
            </div>
            <div className="apartados-acre">
                <iframe id="doc3" src={doc5} title="Documento 5" frameBorder="0"></iframe>
            </div>
            <div className="apartados-acre">
                <iframe id="doc3" src={doc6} title="Documento 6" frameBorder="0"></iframe>
            </div>
            <div className="apartados-acre">
                <iframe id="doc3" src={doc7} title="Documento 7" frameBorder="0"></iframe>
            </div>
        </div>
        <div className="rota-sono">
            
            <div className="apartados-acre">
                <h6 className="low-vol">Certificado Rotametro</h6>
                <br />
                <iframe id="doc3" src={doc8} title="Documento 8" frameBorder="0"></iframe>
            </div>
            <div className="apartados-acre">
                <h6 className="low-vol">Certificado Sonometro</h6>
                <br />
                <iframe id="doc3" src={doc9} title="Documento 9" frameBorder="0"></iframe>
            </div>
        </div>
    </section>
    </>
  )
}
