import React from 'react'
import '../../../estilos/subterraneas.css'
import subterranea from '../../../../images/masubterraneas/subterranea.jpg';
import subterranea2 from '../../../../images/masubterraneas/subterranea2.jpg';



export const Aguasubterraneas = () => {
  return (
    <>
      <section className="subterraneas">
        <div className="titulo-subterraneas">
          <h1>Monitoreo Agua Subterránea</h1>
        </div>
        <div className="cuerpo-subterraneas">
          <div className="list-subterraneas">
            <ul className="info-3">
              <h1 className="subterraneas-ti">
              Estándares de Calidad Ambiental para Agua, aprobado por el D.S. N°004-2017-MINAM
              </h1>
              <h2 className="subterraneas-ti2">
              El monitoreo ambiental de la calidad del agua subterránea en el sector agrícola es esencial para garantizar la sostenibilidad de los recursos hídricos y prevenir la contaminación que pueda surgir como resultado de las actividades agrícolas. Aquí te presento los pasos y consideraciones importantes para llevar a cabo un monitoreo efectivo:
              </h2>
              <li>Identificación de parámetros a monitorear: Los parámetros comunes que se monitorean en la calidad del agua subterránea incluyen la concentración de nutrientes (nitratos, fosfatos), pesticidas, metales pesados, pH, salinidad, oxígeno disuelto y bacterias indicadoras de contaminación fecal. Estos parámetros proporcionan información sobre la contaminación y la calidad general del agua subterránea.</li>
              <li>Selección de ubicaciones de muestreo: Debes seleccionar sitios de muestreo estratégicos que representen diferentes condiciones hidrogeológicas y la influencia de las actividades agrícolas en el área de estudio. Esto puede incluir pozos de agua potable, pozos de observación y puntos de descarga de aguas residuales agrícolas.</li>
              <li>Frecuencia de muestreo: Es importante establecer una frecuencia de muestreo adecuada para capturar las variaciones estacionales y los efectos a largo plazo de las actividades agrícolas en la calidad del agua subterránea. Esto puede implicar muestrear en diferentes épocas del año y durante varios años para evaluar las tendencias a lo largo del tiempo.</li>
              <li>Técnicas de muestreo y análisis: Debes utilizar técnicas de muestreo apropiadas para obtener muestras representativas del agua subterránea, como purga y muestreo de pozos. Las muestras deben ser analizadas en laboratorio utilizando métodos estandarizados para garantizar la precisión y la comparabilidad de los resultados.</li>
              <li>Interpretación de resultados y evaluación de riesgos: Una vez analizadas las muestras, debes interpretar los resultados en el contexto de los estándares de calidad del agua y los posibles riesgos para la salud humana y el medio ambiente. Esto puede implicar comparar los valores medidos con los límites regulatorios y evaluar la vulnerabilidad de los recursos hídricos a la contaminación agrícola.</li>
              <li>Implementación de medidas de gestión y mitigación: Si se identifican problemas de calidad del agua subterránea, es importante tomar medidas para mitigar los impactos negativos. Esto puede incluir la implementación de prácticas agrícolas más sostenibles, la mejora del manejo de fertilizantes y pesticidas, y la protección de las áreas de recarga de acuíferos.</li>
            </ul>
            <div className="div-subterraneas">
              <img className="subterraneas-img"  src={subterranea} alt="Foto monitoreo agua subterránea consultingsel S.R.L" />
              <img className="subterraneas-img2"  src={subterranea2} alt="Foto monitoreo agua subterránea consultingsel S.R.L" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
