import React from 'react'
import '../../estilos/adecuacion.css'
export const Evap = () => {
  return (
     <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Evaluación Ambiental Preliminar (EVAP)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La Evaluación Ambiental Preliminar (EVAP) es un proceso inicial de evaluación que se realiza para identificar y evaluar de manera general los posibles impactos ambientales de un proyecto, actividad o plan agrícola propuesto. Esta evaluación se lleva a cabo en las etapas iniciales de planificación y desarrollo del proyecto y sirve como una herramienta para identificar posibles problemas ambientales y tomar decisiones informadas sobre cómo proceder. Algunos aspectos que suelen considerarse durante una Evaluación Ambiental Preliminar en el sector agrícola son:
            </h2>
           <li>Descripción del proyecto agrícola: Detalles sobre la naturaleza y el alcance del proyecto propuesto, incluyendo la ubicación, el tipo de cultivos a cultivar, las prácticas agrícolas a utilizar y cualquier otra actividad relacionada.</li>
           <li>Identificación de impactos potenciales: Identificación de los posibles impactos ambientales del proyecto en aspectos como la calidad del suelo, el uso del agua, la biodiversidad, la calidad del aire, la salud humana, entre otros.</li>
           <li>Análisis de riesgos: Evaluación de los riesgos potenciales asociados con los impactos identificados, teniendo en cuenta factores como la magnitud de los impactos, la probabilidad de ocurrencia y la sensibilidad de los receptores ambientales.</li>
           <li>Medidas de mitigación: Propuesta de medidas para mitigar o reducir los impactos ambientales negativos identificados durante la evaluación, incluyendo prácticas agrícolas sostenibles, tecnologías de conservación del suelo y del agua, entre otras.</li>
           <li>Cumplimiento normativo: Verificación de que el proyecto cumple con todas las leyes, regulaciones y normativas ambientales aplicables en el ámbito agrícola.</li>
           <li>Consulta y participación: Consideración de comentarios y preocupaciones de partes interesadas, como comunidades locales, organizaciones ambientales y otras partes afectadas por el proyecto propuesto.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
