import React from 'react'
import '../../estilos/adecuacion.css'
export const Ic = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Informes de Cumplimiento (IC)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Los Informes de Cumplimiento (IC) son documentos que detallan el grado en que una entidad, empresa o proyecto agrícola cumple con las regulaciones ambientales, normativas legales y otros requisitos aplicables en relación con sus actividades agrícolas. Estos informes son importantes para demostrar el compromiso de la entidad con la responsabilidad ambiental y para garantizar que se respeten las leyes y regulaciones establecidas para proteger el medio ambiente y la salud pública. Algunos aspectos que suelen incluirse en los Informes de Cumplimiento en el sector agrícola son:
            </h2>
          <li>Cumplimiento normativo: Evaluación del grado en que la entidad cumple con las leyes, regulaciones y normativas ambientales relevantes a nivel local, regional y nacional.</li>
          <li>Monitoreo ambiental: Descripción de los programas de monitoreo ambiental implementados para evaluar y controlar el impacto de las actividades agrícolas en el medio ambiente, incluyendo el monitoreo de la calidad del agua, del suelo y del aire, entre otros.</li>
          <li>Gestión de residuos: Información sobre cómo se manejan y disponen los residuos agrícolas, como los restos de cultivos, los desechos de animales y los envases de agroquímicos, de acuerdo con las regulaciones ambientales.</li>
          <li>Uso de agroquímicos: Detalles sobre el uso de pesticidas, herbicidas, fertilizantes y otros productos químicos en las operaciones agrícolas, incluyendo el cumplimiento de las restricciones y recomendaciones de uso establecidas por las autoridades competentes.</li>
          <li>Conservación de recursos naturales: Información sobre las prácticas implementadas para proteger y conservar los recursos naturales, como la implementación de sistemas de conservación del suelo y del agua, la reforestación, la protección de áreas naturales, entre otras.</li>
          <li>Medidas correctivas: Descripción de las acciones tomadas para corregir cualquier incumplimiento identificado durante el monitoreo o la evaluación del cumplimiento ambiental.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
