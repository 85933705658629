import React from 'react'
import '../../estilos/adecuacion.css'
export const Its = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Informe Técnico sustentatorio (ITS)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            El Informe Técnico Sustentatorio (ITS) es un documento que respalda y justifica técnicamente un proyecto agrícola, proporcionando detalles específicos sobre su viabilidad, métodos de implementación y posibles impactos. Este informe suele ser requerido como parte de los trámites para obtener permisos o autorizaciones para llevar a cabo ciertas actividades agrícolas, especialmente aquellas que pueden tener un impacto significativo en el medio ambiente. Algunos de los elementos que se suelen incluir en un Informe Técnico Sustentatorio en el sector agrícola son:
            </h2>
           <li>Descripción del proyecto: Detalles sobre la naturaleza y el alcance del proyecto agrícola propuesto, incluyendo la ubicación, la extensión de tierra, los cultivos a cultivar, los sistemas de riego, entre otros.</li>
           <li>Justificación técnica: Argumentación sobre la necesidad y viabilidad técnica del proyecto, incluyendo análisis de factibilidad, estudios de mercado, análisis de riesgos, entre otros.</li>
           <li>Metodología de implementación: Descripción detallada de los métodos y técnicas que se utilizarán para llevar a cabo el proyecto agrícola, incluyendo prácticas agrícolas específicas, tecnologías a emplear, y cualquier otro aspecto relevante.</li>
           <li>Análisis de impacto: Evaluación de los posibles impactos del proyecto en el medio ambiente, la salud humana y otros aspectos relevantes, así como propuestas de medidas de mitigación y prevención.</li>
           <li>Cumplimiento normativo: Verificación de que el proyecto cumple con todas las leyes, regulaciones y normativas aplicables en materia agrícola, ambiental y de seguridad.</li>
           <li>Consulta y participación: Descripción de cualquier proceso de consulta pública o participación comunitaria realizado durante la planificación del proyecto, así como los comentarios recibidos y cómo fueron considerados en el diseño final del proyecto.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
