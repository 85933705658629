import React from 'react'
import '../../estilos/bañosdignos.css'
import bañosdignos from '../../../images/bañodignos/bdigno.jpg';
import bañosdignos2 from '../../../images/bañodignos/bdigno2.jpg';

export const Bañosdignos = () => {
  return (
    <>
    <section className="bañosdignos">
      <div className="titulo-bañosdignos">
        <h1>Baños Dignos</h1>
      </div>
      <div className="cuerpo-bañosdignos">
        <div className="list-bañosdignos">
          <ul className="info-7">
            {/* <h1 className="bañosdignos-ti">
            Estándares de Calidad Ambiental para Agua, aprobado por el D.S. N°004-2017-MINAM
            </h1> */}
            <h2 className="bañosdignos-ti2">
            El término "baños dignos" generalmente se refiere a la provisión de instalaciones sanitarias adecuadas y accesibles para todas las personas, independientemente de su género, edad o capacidad física. Estos baños no solo son importantes para la comodidad y la dignidad de las personas, sino también para la salud pública y la prevención de enfermedades.
            Un "paquete tecnológico" en este contexto se refiere a un conjunto de tecnologías, diseños y prácticas recomendadas para la implementación de baños dignos. Esto puede incluir:
            </h2>
            <li>Diseño y construcción de instalaciones sanitarias: Incluyendo la selección de materiales adecuados, diseño ergonómico para personas con discapacidades, sistemas de gestión de desechos, y consideraciones de eficiencia en el uso del agua.</li>
            <li>Tecnologías de tratamiento de aguas residuales: Como sistemas de tratamiento de aguas grises y negras para reducir la contaminación ambiental y reutilizar el agua tratada de manera segura.</li>
            <li>Tecnologías de saneamiento sin agua: Para áreas donde el acceso al agua es limitado, como baños secos o sistemas de compostaje que convierten los desechos humanos en compost seguro y utilizable.</li>
            <li>Tecnologías de higiene personal: Como la provisión de lavamanos con agua y jabón, o la instalación de dispositivos sanitarios como dispensadores de papel higiénico y desinfectantes de manos.</li>
            <li>Educación y capacitación comunitaria: Para promover el uso adecuado de las instalaciones sanitarias, la higiene personal y la gestión adecuada de los desechos, así como para sensibilizar sobre la importancia de los baños dignos para la salud y el bienestar.</li>
          </ul>
          <div className="div-bañosdignos">
            <img className="bañosdignos-img"  src={bañosdignos} alt="Foto bañosdignos consultingsel S.R.L" />
            <img className="bañosdignos-img2"  src={bañosdignos2} alt="Foto bañosdignos consultingsel S.R.L" />
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
