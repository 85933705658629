import React from 'react'
import '../../estilos/adecuacion.css'
export const Mayor = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Uso de capacidad Mayor de suelo</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            El uso de capacidad mayor de suelo implica determinar cuánto se puede cultivar en un área de tierra específica sin comprometer su productividad a largo plazo ni causar daños significativos al medio ambiente. Esto implica adoptar prácticas agrícolas sostenibles que conserven la fertilidad del suelo, minimicen la erosión, protejan la biodiversidad y utilicen eficientemente los recursos naturales disponibles, como el agua y los nutrientes. Los agricultores y planificadores agrícolas pueden utilizar el concepto de uso de capacidad mayor de suelo para tomar decisiones informadas sobre qué cultivos cultivar, cómo gestionar sus fincas de manera sostenible y cómo planificar el desarrollo agrícola en una determinada región. Esto ayuda a evitar la sobreexplotación de los recursos naturales y a garantizar la viabilidad a largo plazo de la agricultura en la zona.
            </h2>
            
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
