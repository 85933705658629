import React from 'react'
import '../../estilos/adecuacion.css'
export const Hidrica = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Huella hídrica</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La huella hídrica es una medida que evalúa el uso y la gestión del agua asociados con la producción agrícola. Es una forma de cuantificar el volumen total de agua utilizada directa e indirectamente en todo el proceso de producción de alimentos, desde el cultivo de los cultivos hasta la producción de alimentos para el ganado. La huella hídrica agrícola se divide comúnmente en tres componentes principales:
            </h2>
            <li>Huella hídrica verde: Se refiere al volumen de agua de lluvia que se utiliza en el crecimiento de los cultivos y es absorbida por las plantas.</li>
            <li>Huella hídrica azul: Representa la cantidad de agua dulce superficial o subterránea extraída para riego, que puede incluir agua de ríos, lagos, acuíferos u otras fuentes.</li>
            <li>Huella hídrica gris: Es el volumen de agua necesario para diluir y transportar los contaminantes generados durante el proceso de producción agrícola, como los productos químicos agrícolas y los residuos de la actividad ganadera.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
