import React from 'react'
import '../../../estilos/residual.css'
import subterranea from '../../../../images/maresidual/residual.jpg';
import subterranea2 from '../../../../images/maresidual/residual2.jpg';
export const Aguaresidual = () => {
  return (
    <>
      <section className="residual">
        <div className="titulo-residual">
          <h1>Monitoreo Agua Residual</h1>
        </div>
        <div className="cuerpo-residual">
          <div className="list-residual">
            <ul className="info-3">
              <h1 className="residual-ti">
              Estándares de Calidad Ambiental para Agua, aprobado por el D.S. N°004-2017-MINAM
              </h1>
              <h2 className="residual-ti2">
              El monitoreo ambiental de la calidad del agua residual en el sector agrícola es crucial para garantizar que las actividades agrícolas no causen contaminación y para promover prácticas sostenibles de gestión del agua. Aquí te presento los pasos y consideraciones importantes para llevar a cabo un monitoreo efectivo:
              </h2>
              <li>Identificación de fuentes de agua residual: Las fuentes de agua residual en el sector agrícola pueden incluir aguas de lavado de equipos y maquinaria, aguas de lavado de cultivos, aguas de procesamiento de alimentos, aguas residuales de sistemas de riego, y aguas de escorrentía agrícola. Es importante identificar y caracterizar estas fuentes de agua residual.</li>
              <li>Selección de parámetros a monitorear: Los parámetros que se deben monitorear en el agua residual agrícola incluyen la carga orgánica (DBO y DQO), nutrientes (nitrógeno y fósforo), sólidos suspendidos, metales pesados, pesticidas y contaminantes microbiológicos (coliformes fecales, E. coli). Estos parámetros ayudan a evaluar la calidad y el impacto ambiental del agua residual.</li>
              <li>Diseño del sistema de monitoreo: Debes diseñar un sistema de monitoreo que incluya la ubicación y frecuencia de muestreo, así como los métodos de análisis de laboratorio a utilizar. Esto puede implicar muestreo periódico en puntos de descarga de agua residual, así como el monitoreo continuo de parámetros clave en sistemas de tratamiento de aguas residuales.</li>
              <li>Muestreo y análisis de agua residual: Se deben recolectar muestras representativas de agua residual de acuerdo con protocolos estandarizados. Las muestras deben ser analizadas en laboratorio utilizando métodos acreditados para garantizar la precisión y la fiabilidad de los resultados.</li>
              <li>Interpretación de resultados y gestión de riesgos: Una vez analizadas las muestras, se deben interpretar los resultados en función de los estándares de calidad del agua y los posibles riesgos para el medio ambiente y la salud humana. Esto puede implicar comparar los valores medidos con los límites regulatorios y evaluar la necesidad de implementar medidas de gestión y mitigación.</li>
              <li>Implementación de medidas de gestión y mitigación: Si se identifican problemas de calidad del agua residual, es importante implementar medidas para reducir la contaminación y minimizar los impactos ambientales. Esto puede incluir la optimización de prácticas de gestión del agua, la instalación de sistemas de tratamiento de aguas residuales, y la adopción de tecnologías de reciclaje y reutilización de agua.</li>
            </ul>
            <div className="div-residual">
              <img className="residual-img"  src={subterranea} alt="Foto monitoreo agua subterránea consultingsel S.R.L" />
              <img className="residual-img2"  src={subterranea2} alt="Foto monitoreo agua subterránea consultingsel S.R.L" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
