import React from 'react'
import '../../estilos/adecuacion.css'
export const Igafom = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Instrumentos de gestión ambiental y fiscalización para la formalización de actividades de pequeña mineria y mineria artesanal (IGAFOM)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            El "Instrumento de Gestión Ambiental y Fiscalización para la Formalización de Actividades de Pequeña Minería y Minería Artesanal" (IGAFOM) es un mecanismo utilizado en varios países, especialmente en América Latina, para regularizar y supervisar las actividades de pequeña minería y minería artesanal. Estos sectores suelen caracterizarse por operaciones más pequeñas, con menor escala de producción y menos recursos tecnológicos que la minería a gran escala. Entre las funciones y características del IGAFOM se incluyen:
            </h2>
            <li>Registro y seguimiento: Establecimiento de un registro oficial de mineros artesanales y de pequeña escala, con seguimiento de sus actividades y cumplimiento de las regulaciones.</li>
            <li>Normativas ambientales: Definición de requisitos ambientales específicos que deben cumplir las operaciones mineras, incluyendo la gestión de residuos, la prevención de la contaminación y la restauración de áreas afectadas.</li>
            <li>Capacitación y asistencia técnica: Provisión de capacitación y asistencia técnica a los mineros para mejorar sus prácticas de extracción, promover la adopción de tecnologías más limpias y fortalecer la seguridad laboral.</li>
            <li>Fiscalización y control: Implementación de mecanismos de supervisión y control para garantizar el cumplimiento de las regulaciones establecidas, con sanciones en caso de incumplimiento.</li>
            <li>Promoción del desarrollo sostenible: Fomento de prácticas mineras responsables que contribuyan al desarrollo económico local y al bienestar de las comunidades, respetando los derechos de los trabajadores y protegiendo el medio ambiente.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
