import React from 'react'
import '../../estilos/adecuacion.css'
import pama1 from '../../../images/pama/uno.jpg'
import pama2 from '../../../images/pama/dos.jpg'
export const Pama = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Programa de Adecuación y Manejo Ambiental (PAMA)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            El Programa de Adecuación y Manejo Ambiental (PAMA) en el sector agrícola es un conjunto de medidas y acciones diseñadas para promover la sostenibilidad ambiental de las actividades agrícolas. Este programa busca mitigar los impactos negativos que pueden surgir de la producción agrícola en el medio ambiente y fomentar prácticas que sean social y ambientalmente responsables. Algunos de los elementos que pueden incluirse en un PAMA agrícola son:
            </h2>
            <li>Conservación de suelos: Implementación de prácticas agrícolas que reduzcan la erosión del suelo, como la siembra directa, la rotación de cultivos y la construcción de terrazas.</li>
            <li>Manejo sostenible del agua: Uso eficiente del agua a través de sistemas de riego más eficaces, recolección y almacenamiento de agua de lluvia, y prácticas que reduzcan la contaminación del agua por agroquímicos.</li>
            <li>Biodiversidad: Promoción de la diversidad biológica en los sistemas agrícolas mediante la conservación de hábitats naturales, la siembra de cultivos diversos y la protección de especies silvestres.</li>
            <li>Gestión de residuos: Manejo adecuado de los residuos agrícolas, como los restos de cultivos y los desechos de animales, a través de técnicas de compostaje, reciclaje y tratamiento adecuado.</li>
            <li>Uso responsable de insumos: Reducción del uso de fertilizantes y pesticidas químicos mediante la adopción de prácticas agroecológicas y orgánicas, así como la implementación de sistemas integrados de control de plagas.</li>
            <li>Educación y capacitación: Sensibilización de los agricultores sobre la importancia de la sostenibilidad ambiental y la adopción de prácticas agrícolas responsables a través de programas de capacitación y extensión agraria.</li>
          </ul>
          <div className="div-adecuacion">
          <img className="diresa-img"  src={pama2} alt="Foto PAMA consultingsel S.R.L" />
            <img className="diresa-img2"  src={pama1} alt="Foto PAMA consultingsel S.R.L" />
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
