import React from 'react'
import '.././estilos/quienes-somos.css'



export const Quienessomos = () => {
    

  return (
    <>
    <section id="uno" className="uno">
       <div className="cuerpo1">
            <div className="info-prese">
                <h1 className="ti-prese">¿Quienes somos?</h1>
                <p className='p-prese'>CONSULTINGSEL S.R.L., es una consultora ambiental con un equipo de profesionales dedicados a brindar asesoramiento y servicios especializados en temas relacionados con el medio ambiente. Su trabajo puede incluir evaluaciones de impacto ambiental, gestión de residuos, conservación de la biodiversidad, cumplimiento normativo y desarrollo sostenible, entre otros aspectos. Su objetivo es ayudar a empresas, gobiernos y organizaciones a tomar decisiones que minimicen su impacto en el medio ambiente y promuevan la sostenibilidad. A la actualidad CONSULTINGSEL S.R.L., se encuentra  acreditada y habilitada en los siguientes sectores:
                </p>
                <ul className="ul--">
                    <li>Ministerio de Desarrollo agrario y Riego</li>
                    <li>Ministerio de Producción</li>
                    <li>Ministerio de Salud</li>
                </ul>
            </div>
            <div className="img-prese"></div>
       </div>
    </section>
    <section className="dos" id="dos" >
       <div className="cuerpo2">
            <div className="nosotros1">
                <h1 className="ti-noso1">Nuestro Propósito</h1>
                <p>Ejecutar servicios de calidad para una mejora continua</p>
            </div>
            <div className="info-noso1">
                <div className="efecto">
                    <h1 className="ti-noso">Misión</h1>
                    <p>Ser una empresa enfocada en proveer servicios de consultoría
                    estratégica que nos permita diferenciar competitivamente el entorno
                    empresarial; teniendo como objetivo principal generar un mayor valor a
                    las empresas que confían en nuestra organización.</p>
                </div>
            </div>
            <div className="info-noso2">
                <div className="efecto">
                    <h1 className="ti-noso">Visión</h1>
                    <p>consolidarnos como la empresa líder en servicios de consultoría
                    ambiental a nivel nacional, brindando un servicio de calidad a nuestros
                    clientes.</p>
                </div>
            </div>
       </div>
    </section>
    <section className="tres">
        <div className="cuerpo3">
            <div className="valores1">
                <h1 className="ti-valores1">Nuestros Valores</h1>
                <p>En Consultingsel cultivamos los siguientes valores:</p>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Liderazgo</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Colaboración</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Respeto Mutuo</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Integridad</h1>
                </div>  
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Calidad</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Solidaridad</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Agilidad</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Aprendizaje</h1>
                </div>
            </div>
            <div className="valores">
                <div className=" info-valores">
                    <h1 className="ti-valores">Enseñanza Continua</h1>
                </div>
            </div>
        </div>
    </section>
    <section className="tres2">
        <div className="cuerpo3-2">
            <div className="logro--">
                <h1 className="logro">Nuestros Logros</h1>
                <p>"La vida es una aventura, atrevete"</p>
            </div>
            <div className=" info-log">
                <div className="img-tanques">
                    <div className="content">
                         <ul>
                            <li className="li-log"><b className="bi bi-chevron-double-right"></b> Tanques sépticos construidos a la actualidad | 16 |</li>
                            <li className="li-log"><b className="bi bi-chevron-double-right"></b> Pozos percoladores construido a la actualidad | 32 |</li>
                        </ul>
                    </div>
                </div>
                <h1 className="ti-log">Construcciones de tanques Sépticos y pozos percolares (PTARD)</h1>
            </div>

            <div className=" info-log">
                <div className="img-biogestores">
                    <div className="content">
                        <ul>
                            <li className="li-log"><b className="bi bi-chevron-double-right"></b> Instalados hasta la actualidad <br />| 12 |</li>
                        </ul>
                    </div>
                </div>
                <h1 className="ti-log">Intalación de biogestores</h1>    
            </div>
            
            <div className=" info-log">
                <div className="img-biocamas">
                    <div className="content">
                        <ul>
                            <li className="li-log"><b className="bi bi-chevron-double-right"></b> Construidos hasta la actualidad | 10 |</li>
                        </ul>
                    </div>
                </div>
                <h1 className="ti-log">Construcción de biocamas</h1> 
            </div>

            <div className=" info-log">
                <div className="img-baño">
                    <div className="content">
                        <ul>
                            <li className="li-log"><b className="bi bi-chevron-double-right"></b> Realizados hasta la actualidad <br />| 11 |</li>
                        </ul>
                    </div>
                </div>
                <h1 className="ti-log">Baños Dignos - Paquete Tecnológico Consulting</h1>
            </div>
            
        </div>
     </section>
    </>
  );
};
