import React from 'react'
import './estilos/decreto.css'  
import doc from '../documents/gestion.pdf'
import doc0 from '../documents/interpretacion.pdf'

export const Decreto = () => {
  return (
    <>
<section className="decreto">
    <div className="decre">
        <h1>Decreto Supremo
        N° 006-2024-MIDAGRI</h1>
    </div>
    <div className="document-decre">
        <div className="apartados-decre">
            <iframe id="doc" src={doc} title="Documento" frameBorder="0" width="100%" height="600"></iframe>
        </div>
    </div>
        <div className="decre">
            <h1>Interpretación de la Norma</h1>
        </div>
        <div className="low2">
            <div className="apartados-decre">
                <iframe id="doc0" src={doc0} title="Documento" frameBorder="0"></iframe>
            </div>
    </div>
</section>

    </>
    
  )
}
