import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './estilos/inicio.css';
import img1 from '../images/inicio/1.jpg';
import img2 from '../images/inicio/2.jpg';
import img3 from '../images/inicio/3.jpg';
import img4 from '../images/inicio/4.jpg';

export const Inicio = () => {
    const [noticias, setNoticias] = useState([]);
    const location = useLocation();

    useEffect(() => {
        // Fetch noticias desde PHP
        fetch('https://consulting-sel.com//src/php/noticias.php')
    .then(response => {
        if (!response.ok) {
            // throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
    })
    .then(data => {
        console.log('Datos recibidos:', data); // Verificar los datos recibidos
        if (Array.isArray(data)) {
            setNoticias(data);
        } else {
            console.error('La respuesta no es un array');
            setNoticias([]);
        }
    })
    .catch(error => console.error('Error fetching noticias:', error));

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        let counter = 1;
    
        const interval = setInterval(() => {
            const auto1 = document.querySelector(".slide");
            if (auto1) {
                if (counter === 1) {
                    auto1.style.transition = 'none';
                } else {
                    auto1.style.transition = '';
                }
                const radioElement = document.getElementById('radio'+counter);
                if (radioElement) {
                    radioElement.checked = true;
                }
                counter++;
                if(counter > 4){
                    counter = 1;
                }
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [location.pathname]);

    window.scrollTo(0, 0);

    return (
        <>
            <div id="portada">
                <div className="slides">
                    <input type="radio" name="radio-btn" id="radio1" title="slider manual"/>
                    <input type="radio" name="radio-btn" id="radio2" title="slider manual"/>
                    <input type="radio" name="radio-btn" id="radio3" title="slider manual"/>
                    <input type="radio" name="radio-btn" id="radio4" title="slider manual"/>

                    <div className="slide first">
                        <img className="img-slide" src={img1} alt="img"/>
                    </div>
                    <div className="slide">
                        <img className="img-slide" src={img2} alt="img"/>
                    </div>
                    <div className="slide">
                        <img className="img-slide" src={img3} alt="img"/>
                    </div>
                    <div className="slide">
                        <img className="img-slide" src={img4} alt="img"/>
                    </div>
                    <div className="auto">
                        <div className="auto-btn1"></div>
                        <div className="auto-btn2"></div>
                        <div className="auto-btn3"></div>
                        <div className="auto-btn4"></div>
                    </div>
                </div>
                <div className="manual">
                    <label htmlFor="radio1" className="manual-btn" title="slider"></label>
                    <label htmlFor="radio2" className="manual-btn" title="slider"></label>
                    <label htmlFor="radio3" className="manual-btn" title="slider"></label>
                    <label htmlFor="radio4" className="manual-btn" title="slider"></label>
                </div>
            </div>

            <section className='noticias'>
                <div className="titu">
                    <h1>Noticias</h1>
                </div>
                <div id="cuerpo">
                    {noticias.length === 0 ? (
                        <p className='sin-pu'>Sin publicaciones</p>
                    ) : (
                        noticias.map((noticia, index) => (
                            <div key={index} className="itens">
                                <a href={noticia.url} target="_blank" rel="noopener noreferrer" className='url'>
                                    <img className='img-portada' src={noticia.portada} alt="portada"/>
                                    <h1>{noticia.titulo}</h1>
                                </a>
                                <p>{noticia.descripcion}</p>
                                
                            </div>
                        ))
                    )}
                </div>
            </section>
        </>
    );
};
