import React from 'react'
import '../../estilos/adecuacion.css'
// import dia1 from '../../../images/dia/uno.jpg'
import dia2 from '../../../images/dia/dos.jpg'

export const Dia = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Declaración de Impacto Ambiental (DIA)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Una Declaración de Impacto Ambiental (DIA) es un informe detallado que evalúa los posibles efectos ambientales de un proyecto agrícola propuesto antes de que se lleve a cabo. Este documento es parte del proceso de evaluación ambiental que muchas veces es obligatorio para proyectos agrícolas de cierto tamaño o con cierto potencial de impacto en el medio ambiente. La Declaración de Impacto Ambiental (DIA) en el sector agrícola generalmente incluye los siguientes elementos:
            </h2>
           <li>Descripción del proyecto: Detalles sobre la naturaleza del proyecto agrícola propuesto, como el tipo de cultivos a cultivar, la extensión de tierra a utilizar, las técnicas agrícolas a implementar, entre otros.</li>
           <li>Análisis de impacto ambiental: Evaluación de los posibles efectos del proyecto en el medio ambiente, incluyendo aspectos como la calidad del suelo, el uso del agua, la biodiversidad, la calidad del aire y la salud humana.</li>
           <li>Identificación de medidas de mitigación: Propuesta de acciones específicas para mitigar, reducir o compensar los impactos ambientales negativos identificados durante el análisis. Esto puede incluir la implementación de tecnologías más limpias, prácticas agrícolas sostenibles, medidas de conservación del suelo y del agua, entre otras.</li>
           <li>Consulta pública: En algunos casos, se permite o se requiere la participación del público en el proceso de evaluación ambiental, donde se pueden presentar comentarios o preocupaciones sobre el proyecto propuesto.</li>
           <li>Cumplimiento normativo: Verificación de que el proyecto cumple con todas las leyes, regulaciones y normativas ambientales aplicables.</li>
           <li>Monitoreo y seguimiento: Establecimiento de un plan para monitorear los impactos ambientales durante la implementación del proyecto y, en algunos casos, después de su finalización, para garantizar el cumplimiento de las medidas de mitigación y la protección continua del medio ambiente.</li>
          </ul>
          <div className="div-adecuacion">
          {/* <img className="diresa-img"  src={dia1} alt="Foto DIA consultingsel S.R.L" /> */}
          <img className="diresa-img"  src={dia2} alt="Foto DIA consultingsel S.R.L" />

          </div>
        </div>
      </div>
    </section>
  </>
  )
}
