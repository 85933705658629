import React from 'react'
import '../../estilos/adecuacion.css'
export const Iisc = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Informe de identificación de sitios contaminados (IISC)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            El Informe de Identificación de Sitios Contaminados (IISC) es un documento que tiene como objetivo identificar y evaluar los sitios donde se ha producido contaminación o existe un riesgo potencial de contaminación debido a actividades agrícolas pasadas o presentes. Este informe es parte del proceso de gestión ambiental y puede ser requerido por las autoridades competentes en materia ambiental. Algunos de los aspectos que se suelen incluir en un Informe de Identificación de Sitios Contaminados en el sector agrícola son:
            </h2>
           <li>Historia del sitio: Recopilación de información sobre la historia del sitio, incluyendo actividades agrícolas anteriores, tipos de cultivos cultivados, uso de agroquímicos (como pesticidas y fertilizantes), almacenamiento de productos químicos, entre otros.</li>
           <li>Inspección del sitio: Realización de inspecciones en el sitio para identificar posibles fuentes de contaminación, tales como tanques de almacenamiento de productos químicos, áreas de aplicación de pesticidas, vertederos de residuos agrícolas, entre otros.</li>
           <li>Muestreo y análisis: Recolección de muestras de suelo, agua y/o aire para su análisis en laboratorio, con el fin de determinar la presencia y concentración de contaminantes agrícolas como pesticidas, herbicidas, fertilizantes y otros productos químicos.</li>
           <li>Evaluación de riesgos: Evaluación del riesgo ambiental y para la salud humana asociado con la contaminación identificada, teniendo en cuenta factores como la naturaleza de los contaminantes, su concentración, la exposición potencial y los receptores sensibles.</li>
           <li>Medidas de mitigación: Propuesta de medidas para mitigar o remediar la contaminación identificada, como la remoción de suelos contaminados, la descontaminación de aguas subterráneas, la aplicación de técnicas de biorremediación, entre otras.</li>
           <li>Informe final: Documentación detallada de los hallazgos del estudio, las conclusiones alcanzadas y las recomendaciones para la gestión y remediación de la contaminación agrícola identificada.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
