import React from 'react'
import '../../estilos/adecuacion.css'
import iga1 from '../../../images/iga/uno.jpg'
import iga2 from '../../../images/iga/dos.jpg'

export const Iga = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Instrumento de gestión ambiental (IGA)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Un Instrumento de Gestión Ambiental (IGA) en el sector agrícola es un conjunto de herramientas, políticas, normativas y acciones diseñadas para promover la gestión sostenible de los recursos naturales y la protección del medio ambiente en actividades agrícolas. Estos instrumentos están destinados a ayudar a los agricultores, empresas agrícolas y autoridades competentes a abordar los desafíos ambientales asociados con la producción de alimentos y otros productos agrícolas. Algunos ejemplos de Instrumentos de Gestión Ambiental en el sector agrícola incluyen:
            </h2>
           <li>Regulaciones y normativas ambientales: Establecimiento de leyes y regulaciones que regulen el uso de pesticidas, fertilizantes, agua y otras prácticas agrícolas para proteger el medio ambiente y la salud humana.</li>
           <li>Certificación agrícola: Desarrollo de programas de certificación que promuevan prácticas agrícolas sostenibles, como la agricultura orgánica, la agricultura de conservación y la producción integrada.</li>
           <li>Programas de incentivos: Implementación de programas de subsidios, créditos fiscales u otros incentivos económicos para alentar a los agricultores a adoptar prácticas agrícolas más sostenibles y respetuosas con el medio ambiente.</li>
           <li>Planificación del uso del suelo: Desarrollo de planes de ordenación del territorio y zonificación agrícola que promuevan un uso sostenible del suelo y eviten la deforestación, la degradación del suelo y la pérdida de hábitat natural.</li>
           <li>Educación y capacitación: Ofrecimiento de programas de educación y capacitación para agricultores sobre prácticas agrícolas sostenibles, manejo integrado de plagas, conservación del suelo y del agua, entre otros temas.</li>
           <li>Monitoreo y seguimiento: Establecimiento de sistemas de monitoreo ambiental para evaluar el impacto de las actividades agrícolas en el medio ambiente y garantizar el cumplimiento de las regulaciones ambientales.</li>
          </ul>
          <div className="div-adecuacion">
          <img className="diresa-img"  src={iga1} alt="Foto IGA consultingsel S.R.L" />
          <img className="diresa-img2"  src={iga2} alt="Foto IGA consultingsel S.R.L" />

          </div>
        </div>
      </div>
    </section>
  </>
  )
}
