import React from 'react'
import '../../estilos/adecuacion.css'
export const Dmrs = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Declaraciones de Manejo de residuos sólidos (DMRS)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Una Declaración de Manejo de Residuos Sólidos (DMRS) es un documento que describe cómo se manejan los residuos sólidos generados como resultado de las actividades agrícolas. Estos residuos pueden incluir restos de cultivos, desechos de animales, envases de productos agrícolas, entre otros materiales sólidos asociados con la producción agrícola. La DMRS en el sector agrícola generalmente incluye los siguientes elementos:
            </h2>
            <li>Identificación de los tipos de residuos: Descripción de los diferentes tipos de residuos sólidos generados en las operaciones agrícolas, como restos de cosechas, paja, estiércol, envases vacíos de agroquímicos, entre otros.</li>
            <li>Generación de residuos: Estimación de la cantidad y frecuencia de generación de cada tipo de residuo sólido, considerando factores como la escala de las operaciones agrícolas y los métodos de producción utilizados.</li>
            <li>Almacenamiento temporal: Descripción de cómo se almacenan temporalmente los residuos sólidos en la finca o explotación agrícola antes de su disposición final, incluyendo medidas para prevenir la contaminación y los riesgos para la salud.</li>
            <li>Manejo y tratamiento: Detalles sobre cómo se manejan y tratan los diferentes tipos de residuos sólidos, incluyendo prácticas de compostaje, reciclaje, reutilización y disposición final segura.</li>
            <li>Cumplimiento normativo: Verificación de que el manejo de residuos sólidos en la operación agrícola cumple con todas las leyes, regulaciones y normativas ambientales aplicables en materia de residuos sólidos y gestión de residuos.</li>
            <li>Educación y sensibilización: Descripción de programas educativos y de sensibilización dirigidos a los trabajadores agrícolas y otras partes interesadas sobre la importancia del manejo adecuado de residuos sólidos y las prácticas sostenibles relacionadas.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
