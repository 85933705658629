import React from 'react'
import '../../estilos/adecuacion.css'
export const Instrumentos = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Informe de test de percolación</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La modificación de instrumentos ambientales en el sector agrícola se refiere a cambios o ajustes realizados en los instrumentos de gestión ambiental aplicables a actividades agrícolas específicas. Estos instrumentos ambientales pueden incluir permisos, licencias, autorizaciones, planes de manejo ambiental, entre otros, que regulan y supervisan las actividades agrícolas para garantizar su compatibilidad con las normativas ambientales y la protección del medio ambiente. Algunos ejemplos de modificaciones en instrumentos ambientales en el sector agrícola podrían incluir:
            </h2>
            <li>Actualización de planes de manejo ambiental: Revisión y ajuste de los planes de manejo ambiental para incluir medidas adicionales de mitigación de impactos ambientales o incorporar prácticas agrícolas más sostenibles.</li>
            <li>Modificación de permisos o autorizaciones: Cambios en los permisos o autorizaciones otorgados para actividades agrícolas, como la ampliación de áreas cultivadas, la introducción de nuevos cultivos o la implementación de sistemas de riego más eficientes.</li>
            <li>Revisión de límites de emisión: Ajustes en los límites de emisión de contaminantes atmosféricos, descargas de aguas residuales o manejo de residuos agrícolas para cumplir con estándares ambientales más estrictos.</li>
            <li>Incorporación de nuevas tecnologías: Actualización de instrumentos ambientales para incorporar tecnologías más limpias y eficientes en el manejo de recursos naturales, como sistemas de riego por goteo, agricultura de conservación o energías renovables.</li>
            <li>Inclusión de medidas de adaptación al cambio climático: Integración de medidas de adaptación al cambio climático en los instrumentos de gestión ambiental para hacer frente a los impactos climáticos en la agricultura, como sequías, inundaciones o cambios en los patrones de cultivo.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
