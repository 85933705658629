import React from 'react'
import '../../estilos/pozoprecolador.css'
import pozo from '../../../images/pozoprecolador/pozo.jpg';
import pozo2 from '../../../images/pozoprecolador/pozo2.jpg';
export const Pozoprecolador = () => {
  return (
    <>
      <section className="pozo">
        <div className="titulo-pozo">
          <h1>Pozo Precolador</h1>
        </div>
        <div className="cuerpo-pozo">
          <div className="list-pozo">
            <ul className="info-6">
              {/* <h1 className="pozo-ti">
              Estándares de Calidad Ambiental para Agua, aprobado por el D.S. N°004-2017-MINAM
              </h1> */}
              <h2 className="pozo-ti2">
              Un pozo percolador, también conocido como pozo de absorción o pozo de infiltración, es una estructura subterránea utilizada en sistemas de tratamiento de aguas residuales para la disposición final del efluente tratado. Es una parte importante de los sistemas sépticos y sistemas de tratamiento descentralizados.
              Aquí tienes algunas características clave de un pozo percolador:
              </h2>
              <li>Función: El pozo percolador se utiliza para permitir que el efluente tratado de un tanque séptico o de un sistema de tratamiento similar se infiltre gradualmente en el suelo. Este proceso ayuda a purificar aún más el agua residual mediante la filtración natural a través del suelo, donde los microorganismos presentes pueden continuar descomponiendo los contaminantes.</li>
              <li>Diseño: Un pozo percolador típicamente consiste en una serie de tubos perforados o cámaras conectadas que se entierran en el suelo. Estas tuberías o cámaras están rodeadas de material filtrante como grava o piedra triturada, que ayuda a facilitar la distribución uniforme del agua y proporciona espacio para la filtración.</li>
              <li>Ubicación: Los pozos percoladores deben ubicarse en áreas con suelo adecuado para la filtración y absorción del agua residual tratada. Se evitan áreas con suelos arcillosos o rocosos, ya que pueden tener una capacidad de infiltración limitada. Se consideran factores como el nivel freático, la pendiente del terreno y la distancia a las fuentes de agua subterránea para evitar la contaminación.</li>
              <li>Mantenimiento: Es importante realizar un mantenimiento regular del pozo percolador para garantizar su funcionamiento eficaz a lo largo del tiempo. Esto puede incluir la limpieza periódica de las tuberías o cámaras para eliminar cualquier obstrucción, así como la inspección del sistema para detectar posibles problemas de funcionamiento.</li>
              <li>Normativas y regulaciones: Las normativas locales y nacionales suelen establecer requisitos específicos para el diseño, la instalación y el mantenimiento de los pozos percoladores. Estas regulaciones están destinadas a garantizar que el sistema de disposición de aguas residuales sea seguro, efectivo y respetuoso con el medio ambiente.</li>
            </ul>
            <div className="div-pozo">
              <img className="pozo-img"  src={pozo} alt="Foto pozo precolador consultingsel S.R.L" />
              <img className="pozo-img2"  src={pozo2} alt="Foto pozo precolador consultingsel S.R.L" />

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
