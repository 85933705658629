import React from 'react'
import '../../estilos/adecuacion.css'
export const Eiasd = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Estudio de Impacto Ambiental Semidetallado (EIAsd)</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Un Estudio de Impacto Ambiental Semidetallado (EIAsd) es un análisis exhaustivo que evalúa los posibles efectos ambientales de un proyecto agrícola propuesto. Este estudio proporciona información detallada sobre cómo el proyecto afectará al medio ambiente y a las comunidades locales, y propone medidas para mitigar los impactos negativos identificados. Algunos de los aspectos que suelen abordarse en un Estudio de Impacto Ambiental Semidetallado en el sector agrícola son:
            </h2>
           <li>Descripción del proyecto: Detalles sobre la naturaleza y el alcance del proyecto agrícola, incluyendo la ubicación, el tamaño del área afectada, los cultivos a cultivar, las prácticas agrícolas a utilizar y cualquier infraestructura asociada.</li>
           <li>Identificación de impactos: Evaluación de los posibles impactos del proyecto en el medio ambiente, incluyendo aspectos como la calidad del suelo, el uso del agua, la biodiversidad, la calidad del aire, la salud humana y los recursos naturales.</li>
           <li>Análisis de riesgos: Evaluación de los riesgos potenciales asociados con los impactos identificados, teniendo en cuenta factores como la magnitud de los impactos, la probabilidad de ocurrencia y la sensibilidad de los receptores ambientales.</li>
           <li>Medidas de mitigación: Propuesta de medidas para evitar, minimizar o compensar los impactos ambientales negativos identificados durante el análisis, incluyendo prácticas agrícolas sostenibles, tecnologías de conservación del suelo y del agua, y medidas de protección de la biodiversidad.</li>
           <li>Plan de monitoreo: Desarrollo de un plan para monitorear los impactos ambientales durante la implementación del proyecto y después de su finalización, con el fin de garantizar el cumplimiento de las medidas de mitigación y la protección continua del medio ambiente.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
