import React from 'react'
import '../../estilos/diresa.css'
// import consumohumano from '../../../../images/consumohumano/consumo.jpg';
// import consumohumano2 from '../../../../images/consumohumano/consumo2.jpg';
export const Diresa = () => {
  return (
    <>
    <section className="diresa">
      <div className="titulo-diresa">
        <h1>Exp. Diresa</h1>
      </div>
      <div className="cuerpo-diresa">
        <div className="list-diresa">
          <ul className="info-9">
            <h1 className="diresa-ti">
            AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA)
            </h1>
            <h2 className="diresa-ti2">
            La Dirección Regional de Salud (DIRESA) es la entidad encargada de regular y supervisar la salud pública a nivel regional en Perú. Al igual que DIGESA a nivel nacional, la DIRESA tiene competencia en diversas áreas, incluyendo la regulación y otorgamiento de autorizaciones sanitarias para establecimientos, actividades y servicios relacionados con la salud pública y la atención médica. Para obtener una autorización sanitaria de la DIRESA en Perú, generalmente se deben seguir ciertos pasos y cumplir con ciertos requisitos. A continuación, te presento algunos aspectos generales relacionados con el proceso de obtener una autorización sanitaria de la DIRESA:

            </h2>
           <li>Identificación de la actividad o establecimiento: Lo primero es identificar la actividad específica o el establecimiento para el cual se necesita la autorización sanitaria. Esto puede incluir establecimientos de salud, centros de atención médica, establecimientos de alimentos, servicios de salud ambiental, entre otros.</li>
           <li>Requisitos y documentación necesaria: Cada tipo de actividad o establecimiento estará sujeto a requisitos específicos que deben cumplirse para obtener la autorización sanitaria. Estos requisitos pueden incluir la presentación de documentos técnicos, certificados de calidad, registros sanitarios, entre otros.</li>
           <li>Presentación de solicitud: Una vez identificados los requisitos, se debe presentar una solicitud de autorización sanitaria ante la DIRESA correspondiente. Esto puede implicar completar formularios específicos y adjuntar la documentación requerida.</li>
           <li>Evaluación y trámite: La DIRESA llevará a cabo una evaluación de la solicitud y de la documentación presentada. Esto puede incluir inspecciones en el lugar, revisión de documentos técnicos y análisis de cumplimiento de normativas y estándares sanitarios.</li>
           <li>Emisión de la autorización: Si se determina que se cumplen todos los requisitos y que la actividad o establecimiento cumple con las normativas sanitarias aplicables, la DIRESA emitirá la autorización sanitaria correspondiente. Esta autorización puede tener una validez limitada en el tiempo y puede estar sujeta a condiciones específicas que deben cumplirse.</li>
          </ul>
          <div className="div-diresa">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
