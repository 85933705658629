import React from 'react'
import error404 from '../images/error-404.jpg'

export const Error404 = () => {
  return (
    <div className='container' >  
        <center >
            <img src={error404}  style={{ width:'100%', height:'100vh'}} alt="error404" />
        </center>
    
    </div>
  )
}
