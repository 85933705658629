import React from 'react'
import '../../estilos/adecuacion.css'
export const Test = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Informe de test de percolación</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Un Informe de Test de Percolación es un documento que describe los resultados y las conclusiones de un test de percolación realizado en el suelo para evaluar su capacidad de infiltración y drenaje. Este tipo de informe es importante en la agricultura, especialmente en el diseño y la gestión de sistemas de riego, la conservación del agua y el manejo adecuado de las tierras agrícolas. El Informe de Test de Percolación en el sector agrícola suele incluir los siguientes elementos:
            </h2>
            <li>Descripción del procedimiento: Detalles sobre cómo se realizó el test de percolación, incluyendo el número de perforaciones, la profundidad de las mismas, la cantidad de agua utilizada y el tiempo de observación.</li>
            <li>Resultados del test: Datos obtenidos durante el test de percolación, como la velocidad de infiltración del agua, la tasa de absorción del suelo, la capacidad de retención de agua, entre otros.</li>
            <li>Análisis y interpretación: Evaluación de los resultados obtenidos y su significado en términos de la capacidad de drenaje y retención de agua del suelo, así como su relevancia para las prácticas agrícolas y de manejo de agua en la finca.</li>
            <li>Recomendaciones: Propuestas de medidas o prácticas para mejorar el drenaje y la gestión del agua en la finca agrícola, basadas en los resultados del test de percolación.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
