import React from 'react'

export const Participación = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Ejecución de tallares de participación ciudadana</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La ejecución de talleres de participación ciudadana en el sector agrícola implica la organización y realización de sesiones de trabajo donde se involucra a diversos actores, incluyendo agricultores, comunidades locales, organizaciones no gubernamentales, autoridades gubernamentales y otros interesados, para discutir temas relacionados con la agricultura, el desarrollo rural y la gestión de recursos naturales. Estos talleres tienen como objetivo fomentar la participación activa y la colaboración entre los diferentes actores involucrados en el sector agrícola, promoviendo el diálogo, la identificación de problemas, la búsqueda de soluciones y la toma de decisiones informadas. Algunos de los objetivos y aspectos que se abordan en los talleres de participación ciudadana en el sector agrícola pueden incluir:
            </h2>
           <li>Identificación de necesidades y preocupaciones: Se proporciona un espacio para que los participantes expresen sus necesidades, preocupaciones y expectativas en relación con la agricultura y el desarrollo rural en su comunidad o región.</li>
           <li>Intercambio de conocimientos y experiencias: Se facilita el intercambio de conocimientos y experiencias entre los participantes, incluyendo técnicas agrícolas, prácticas de conservación de recursos naturales, estrategias de adaptación al cambio climático, entre otros.</li>
           <li>Análisis de problemas y desafíos: Se discuten y analizan los problemas y desafíos que enfrenta el sector agrícola, como la degradación del suelo, la escasez de agua, los bajos ingresos de los agricultores, y se identifican posibles soluciones y acciones para abordarlos.</li>
           <li>Planificación y diseño de proyectos: Se desarrollan planes y proyectos agrícolas en colaboración con los participantes, teniendo en cuenta sus necesidades y perspectivas, así como los recursos disponibles y las oportunidades de financiamiento.</li>
           <li>Fortalecimiento de capacidades: Se brinda capacitación y apoyo técnico a los participantes para fortalecer sus habilidades en áreas como la gestión de cultivos, la conservación del suelo, la gestión del agua, la diversificación de cultivos, entre otros.</li>
           <li>Promoción de la cooperación y la colaboración: Se fomenta la cooperación y la colaboración entre los diferentes actores del sector agrícola, incluyendo agricultores, organizaciones comunitarias, instituciones gubernamentales y entidades privadas, para trabajar juntos hacia objetivos comunes de desarrollo agrícola sostenible.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
