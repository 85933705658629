import React from 'react'
import '../../estilos/adecuacion.css'
export const Hidrogeologicos = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Elaboración de estudios Hidrogeológicos</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La elaboración de estudios hidrogeológicos en el sector agrícola implica la investigación y caracterización de los recursos hídricos subterráneos, así como su interacción con el medio ambiente, con el fin de comprender mejor la disponibilidad y la calidad del agua para el riego y otras necesidades agrícolas. Estos estudios son esenciales para el desarrollo sostenible de proyectos agrícolas, ya que el agua subterránea es una fuente vital de riego en muchas regiones agrícolas del mundo. Algunos aspectos que se abordan en la elaboración de estudios hidrogeológicos en el sector agrícola incluyen:
            </h2>
           <li>Caracterización del acuífero: Se investiga la geología, la estratigrafía y la hidrología del acuífero subterráneo para comprender su capacidad de almacenamiento, su recarga y su descarga, así como su relación con las aguas superficiales.</li>
           <li>Monitoreo de niveles de agua: Se realizan mediciones de los niveles de agua subterránea a lo largo del tiempo para evaluar las fluctuaciones estacionales y a largo plazo, así como para identificar tendencias de agotamiento o recarga del acuífero.</li>
           <li>Calidad del agua: Se analiza la calidad química y bacteriológica del agua subterránea para determinar su idoneidad para el riego y otras aplicaciones agrícolas, así como para identificar posibles fuentes de contaminación.</li>
           <li>Modelado hidrogeológico: Se utiliza software especializado para simular el flujo de agua subterránea y prever los efectos de diferentes escenarios de extracción y recarga en el acuífero, lo que permite una gestión más eficiente y sostenible del recurso hídrico.</li>
           <li>Diseño de sistemas de riego: Con base en la información hidrogeológica recopilada, se desarrollan recomendaciones para el diseño y la operación de sistemas de riego que optimicen el uso del agua subterránea y minimicen los impactos ambientales.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
