import React from 'react'
import '../../estilos/ruido.css'
import ruido from '../../../images/mruido/ruido.jpg'
import ruido2 from '../../../images/mruido/ruido2.jpg'

export const Monitoreoruido = () => {
  return (
    <>
      <section className="ruido">
        <div className="titulo-ruido">
          <h1>Monitoreo Ruido</h1>
        </div>
        <div className="cuerpo-ruido">
          <div className="list-ruido">
            <ul className="info-3">
              <h1 className="ruido-ti">
              Estándares Nacionales de Calidad Ambiental para ruido en conformidad al D.S N°085-2003-PCM
              </h1>
              <h2 className="ruido-ti2">
              El monitoreo ambiental de la calidad de ruido en el sector agrícola es importante para comprender y gestionar los impactos del ruido generado por las actividades agrícolas en el entorno. Aquí tienes algunos pasos y consideraciones importantes para llevar a cabo un monitoreo efectivo:
              </h2>
              <li>Identificación de fuentes de ruido agrícola: Las actividades agrícolas pueden generar ruido de diversas fuentes, como maquinaria agrícola (tractores, cosechadoras, equipos de riego), sistemas de ventilación en instalaciones ganaderas, y transporte de productos agrícolas. Es importante identificar las principales fuentes de ruido en la zona de estudio.</li>
              <li>Selección de ubicaciones de monitoreo: Debes colocar los dispositivos de monitoreo en lugares estratégicos que representen diferentes condiciones de exposición al ruido. Esto puede incluir áreas cercanas a campos de cultivo, instalaciones ganaderas, carreteras y zonas residenciales.</li>
              <li>Elección de parámetros a medir: Los parámetros comunes que se miden en el monitoreo de ruido incluyen el nivel de presión sonora (en decibelios, dB), la frecuencia del sonido y la duración de los eventos de ruido. Estos parámetros ayudan a evaluar la intensidad, la frecuencia y la duración del ruido generado por las actividades agrícolas.</li>
              <li>Selección de equipos de monitoreo: Debes elegir los dispositivos de monitoreo adecuados para cada parámetro que deseas medir. Esto puede incluir sonómetros portátiles para mediciones a corto plazo, estaciones de monitoreo fijas para mediciones a largo plazo, y equipos especializados para la caracterización del ruido en frecuencia.</li>
              <li>Análisis de datos y evaluación de impacto: Una vez recopilados los datos, es importante analizarlos para evaluar el impacto del ruido generado por las actividades agrícolas en el entorno. Esto puede implicar comparar los niveles de ruido medidos con estándares de calidad acústica, evaluar la exposición de la población cercana y identificar áreas de preocupación.</li>
              <li>Implementación de medidas de control y mitigación: Si se identifican problemas de ruido, es importante tomar medidas para controlar y mitigar sus impactos negativos. Esto puede incluir el uso de tecnologías más silenciosas en maquinaria agrícola, la implementación de barreras de sonido alrededor de instalaciones ruidosas, y la planificación adecuada de las actividades agrícolas para minimizar la exposición al ruido.</li>
            </ul>
            <div className="div-ruido">
              <img className="ruido-img"  src={ruido} alt="Foto monitoreo ruido consultingsel S.R.L" />
              <img className="ruido-img2"  src={ruido2} alt="Foto monitoreo ruido consultingsel S.R.L" />

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
