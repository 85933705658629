
import './estilos/header.css';
import logo from '../images/CONSULTINGSEL.png';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export const Encabezado = () => {
//reponsive
  useEffect(() => {
    const menutoggle = document.querySelector('.menutoggle');
    const header = document.querySelector('header');

    const handleClick = () => {
      header.classList.toggle('active');
    };

    menutoggle.addEventListener('click', handleClick);

    return () => {
      menutoggle.removeEventListener('click', handleClick);
    };
  }, []);

  //rotate
  const [isHoveredNosotros, setIsHoveredNosotros] = useState(false);
  const [isHoveredServicios, setIsHoveredServicios] = useState(false);

  const handleMouseEnterNosotros = () => {
    setIsHoveredNosotros(true);
  };

  const handleMouseLeaveNosotros = () => {
    setIsHoveredNosotros(false);
  };

  const handleMouseEnterServicios = () => {
    setIsHoveredServicios(true);
  };

  const handleMouseLeaveServicios = () => {
    setIsHoveredServicios(false);
  };
  //Posicion inicial + ocultar submenu
  
  const [hoverStyle, setHoverStyle] = useState({});

  const scrollToTop = () => {
    scrollToTopclick();
    handleItemClick();
    responsivemenu();
  };
  
  const scrollToTopclick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleItemClick = () => {
    setHoverStyle({ display: 'none' });
    setTimeout(() => {
      setHoverStyle({});
    }, 500);
  };

  const nav =document.querySelector('header');
  const responsivemenu = (element) => {
    nav.classList.remove('active');
  };

  return (
    <>
      <header id="header">
      <NavLink to="/inicio" ><img className="logo" src={logo} alt="logo-consultingsel" /></NavLink>
      <div className="menutoggle"></div>
        <nav id="nav">
          <ul>
          <li id="pp" onMouseEnter={handleMouseEnterNosotros} onMouseLeave={handleMouseLeaveNosotros}>
            <h5>Nosotros <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" translate="no" fill="currentColor" className={`bi bi-chevron-right ${isHoveredNosotros? 'rotated' : ''}`} viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                </svg></h5>
              <ul id="ppp" style={hoverStyle} onClick={scrollToTop}>  
                <li><NavLink to="/quienes-somos" className="p-1">Quienes somos</NavLink></li>
                <li><NavLink to="/equipo" className="p-1">Equipo</NavLink></li>
              </ul>
            </li>
            <li className="aa" onMouseEnter={handleMouseEnterServicios} onMouseLeave={handleMouseLeaveServicios}>
              <h5>Servicios <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" translate="no" fill="currentColor" className={`bi bi-chevron-right ${isHoveredServicios  ? 'rotated' : ''}`} viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                </svg></h5>
              <ul className="aaa"style={hoverStyle} onClick={scrollToTop}>
                <ul>
                    <li className="li-titulo">Monitoreos</li>
                    <li><NavLink to="/monitoreo-aire" >Monitoreo aire</NavLink></li>
                    <li><NavLink to="/monitoreo-suelo" >Monitoreo suelo</NavLink></li>
                    <li><NavLink to="/monitoreo-ruido" >Monitoreo ruido</NavLink></li>
                    <li><NavLink to="/monitoreo-biologico" >Monitoreo biológico</NavLink></li>
                    <li className="sub-menus" >Monitoreo agua</li>
                    <ul>
                      <li><NavLink to="/monitoreo-agua-subterraneas" >Agua subterránea</NavLink></li>
                      <li><NavLink to="/monitoreo-agua-residual" >Agua residual</NavLink></li>
                      <li><NavLink to="/consumo-humano" >Consumo humano</NavLink></li>
                    </ul>
              </ul>
              <ul>
                    <li className="li-titulo">Construcciones</li>
                    <li><NavLink to="/tanque-septico" >Tanque séptico</NavLink></li>
                    <li><NavLink to="/pozo-precolador" >Pozo precolador</NavLink></li>
                    <li><NavLink to="/biodigestores" >Biodigestores</NavLink></li>
                    <li><NavLink to="/baños-dignos" >Baños dignos</NavLink></li>
                    <ul>
                    <li className="li-titulo" >Auto de Ptard</li>
                    <li><NavLink to="/diresa" >Exp. Diresa</NavLink></li>
                    <li><NavLink to="/digesa" >Exp. Digesa</NavLink></li>      
              </ul> 
              </ul>
              
              <ul className='estudio'>
                <li className="li-titulo">Estudios ambientales</li>
                    <li><NavLink to="/declaracion-ambiental-de-actividades-en-curso" >Declaración Ambiental de Actividades en Curso (DAAC)</NavLink></li>
                    <li><NavLink to="/programa-de-adecuacion-y-manejo-ambiental" >Programa de Adecuación y Manejo Ambiental (PAMA)</NavLink></li>
                    <li><NavLink to="/declaracion-de-adecuacion-ambiental" >Declaración de Adecuación Ambiental (DAA)</NavLink></li>
                    <li><NavLink to="/instrumento-de-gestion-ambientals" >Instrumento de gestión ambiental (IGA)</NavLink></li>
                    <li><NavLink to="/declaracion-de-impacto-ambiental" >Declaración de Impacto Ambiental (DIA)</NavLink></li>
              </ul>
              <ul>  
                    <li><NavLink to="/informe-tecnico-sustentatorio" >Informe Técnico sustentatorio (ITS)</NavLink></li>
                    <li><NavLink to="/informe-de-identificacion-de-sitios-contaminados" >Informe de identificación de sitios contaminados (IISC)</NavLink></li>
                    <li><NavLink to="/evaluación-ambiental-preliminar" >Evaluación Ambiental Preliminar (EVAP)</NavLink></li>
                    <li><NavLink to="/informes-de-cumplimiento" >Informes de Cumplimiento (IC)</NavLink></li>
                    <li><NavLink to="/declaraciones-de-manejo-de-residuos-solidos" >Declaraciones de Manejo de residuos sólidos (DMRS)</NavLink></li>
                    <li><NavLink to="/informes-de-letrinas" >Informes de Letrinas</NavLink></li>
                    
              </ul>
              <ul>
                    <li><NavLink to="/informe-de-test-de-percolacion" >Informe de test de percolación</NavLink></li>
                    <li><NavLink to="/estudio-de-impacto-ambiental-Semidetallado" >Estudio de Impacto Ambiental Semidetallado (EIAsd)</NavLink></li>
                    <li><NavLink to="/estudio-de-impacto-ambiental" >Estudio de impacto ambiental (EIAsd)</NavLink></li>
                    <li><NavLink to="/elaboracion-de-estudios-geofisicos" >Elaboración de estudios geofísicos</NavLink></li>
                    <li><NavLink to="/elaboracion-de-estudios-hidrogeológicos" >Elaboración de estudios Hidrogeológicos</NavLink></li>
                    <li><NavLink to="/modificacion-de-instrumentos-ambientales" >Modificación de instrumentos ambientales</NavLink></li>
              </ul>
              <ul>

                    <li><NavLink to="/ejecucion-de-tallares-de-participacion-ciudadana" >Ejecución de tallares de participación ciudadana</NavLink></li>
                    <li><NavLink to="/instrumentos-de-gestion-ambiental-y-fiscalizacion" >Instrumentos de gestión ambiental y fiscalización para la formalización de actividades de pequeña mineria y mineria artesanal (IGAFOM) </NavLink></li>
                    <li><NavLink to="/actualizacion-de-instrumentos-ambientales" >Actualización de instrumentos ambientales</NavLink></li>
                    <li><NavLink to="/capacitaciones-ambientales" >Capacitaciones ambientales</NavLink></li>
              </ul>
              <ul>
                    <li><NavLink to="/uso-de-capacidad-mayor-de-suelo" >Uso de capacidad Mayor de suelo</NavLink></li>
                    <li><NavLink to="huella-hidrica" >Huella hídrica</NavLink></li>
              </ul>
              </ul>
            </li>
            <li><NavLink to="/acreditaciones" className="a" onClick={scrollToTop}>Acreditaciones</NavLink></li>
            <li><NavLink to="/decreto-midagir" className="a" onClick={scrollToTop}>Decreto-MIDAGR</NavLink></li>
            <li><NavLink to="/clientes" className="a" onClick={scrollToTop}>Clientes</NavLink> </li>
            <li><NavLink /* to="/resoluciones"*/ className="b" onClick={scrollToTop}>Resoluciones <p style={{ fontSize:'0.8rem', color:'green'}}>Próximamente</p></NavLink></li>

          </ul>
        </nav>
      </header>
    </>
  );
  };

