import React from 'react'
import '../../estilos/adecuacion.css'
export const Geofisicos = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Elaboración de estudios geofísicos</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            La elaboración de estudios geofísicos en el sector agrícola implica la aplicación de técnicas geofísicas para investigar y caracterizar el subsuelo con el objetivo de obtener información relevante para la gestión y el desarrollo de proyectos agrícolas. Estos estudios son útiles para comprender la estructura, composición y propiedades físicas del suelo y los depósitos subterráneos, lo que puede proporcionar información valiosa para la toma de decisiones en actividades agrícolas como la irrigación, la fertilización, la selección de cultivos y la gestión de recursos hídricos. Algunas de las técnicas geofísicas comúnmente utilizadas en la elaboración de estudios geofísicos en el sector agrícola incluyen:
            </h2>
           <li>Tomografía eléctrica: Esta técnica mide la resistividad eléctrica del subsuelo para detectar cambios en la composición y la humedad del suelo, así como la presencia de capas geológicas y estructuras subterráneas.</li>
           <li>Sondeos electromagnéticos: Se utilizan para medir la conductividad eléctrica del suelo, lo que puede proporcionar información sobre la salinidad, la humedad y la textura del suelo.</li>
           <li>Radar de penetración terrestre (GPR): Esta técnica utiliza ondas electromagnéticas para detectar cambios en la estructura y la composición del subsuelo, como capas de agua, raíces de plantas, cavidades y obstáculos.</li>
           <li>Sísmica de refracción y reflexión: Estas técnicas se utilizan para estudiar las propiedades físicas de las capas subterráneas, incluyendo la profundidad del nivel freático, la densidad del suelo y la presencia de fracturas u otras discontinuidades geológicas.</li>
           <li>Magnetometría: Se utiliza para detectar anomalías magnéticas en el subsuelo, que pueden indicar la presencia de minerales, estructuras geológicas o artefactos arqueológicos.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
