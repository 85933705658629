import React from 'react'
import '../../estilos/adecuacion.css'
export const Actualizacion = () => {
  return (
    <>
    <section className="adecuacion">
      <div className="titulo-adecuacion">
        <h1>Actualización de instrumentos ambientales</h1>
      </div>
      <div className="cuerpo-adecuacion">
        <div className="list-adecuacion">
          <ul className="info-10">
            <h1 className="adecuacion-ti">
            {/* AUTORIZACION SANITARIA PARA FUNCIONAMIENTO DE TANQUE SEPTICO E INFILTRACION EN EL TERRENO – DIRESA (DESA) */}
            </h1>
            <h2 className="adecuacion-ti2">
            Una Actualización de Instrumentos Ambientales en el sector agrícola se refiere al proceso de revisión y modificación de los instrumentos de gestión ambiental que regulan y supervisan las actividades agrícolas. Estos instrumentos pueden incluir normativas, políticas, programas, permisos, licencias, planes de manejo ambiental, entre otros, que tienen como objetivo proteger el medio ambiente y promover prácticas agrícolas sostenibles. Algunas razones comunes para la actualización de instrumentos ambientales en la agricultura pueden incluir:
            </h2>
          <li>Cambios en la legislación ambiental: Las actualizaciones pueden ser necesarias para garantizar que los instrumentos cumplan con las últimas leyes y regulaciones ambientales.</li>
          <li>Avances tecnológicos: La incorporación de nuevas tecnologías agrícolas puede requerir la actualización de los instrumentos para promover prácticas más eficientes y sostenibles.</li>
          <li>Cambios en las condiciones ambientales: Las modificaciones climáticas u otros cambios en el entorno pueden requerir ajustes en los instrumentos para abordar problemas como la escasez de agua, la erosión del suelo o la pérdida de biodiversidad.</li>
          <li>Incorporación de mejores prácticas: Las actualizaciones pueden incluir la incorporación de mejores prácticas agrícolas y ambientales que han surgido a través de la investigación y la experiencia.</li>
          <li>Participación ciudadana: La consulta con agricultores, comunidades locales y otras partes interesadas puede identificar áreas en las que los instrumentos existentes necesitan ser mejorados o ajustados para abordar preocupaciones específicas.</li>
          </ul>
          <div className="div-adecuacion">
            {/* <img className="diresa-img"  src={diresa} alt="Foto diresa consultingsel S.R.L" />
            <img className="diresa-img2"  src={diresa2} alt="Foto diresa consultingsel S.R.L" /> */}
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
