
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';

import { Encabezado } from './components/Encabezado';

import { Inicio } from './components/Inicio';
import {Footer} from './components/Footer'
import { Equipo } from './components/Nosotros/Equipo';
import { Quienessomos } from './components/Nosotros/Quienessomos';
import { Acreditaciones } from './components/Nosotros/Acreditaciones'

import { Error404 } from './components/Error404';

import { Tanqueseptico } from './components/Servicios/construcciones/Tanqueseptico';
import { Pozoprecolador } from './components/Servicios/construcciones/Pozoprecolador';
import { Biodigestores } from './components/Servicios/construcciones/Biodigestores';
import { Bañosdignos } from './components/Servicios/construcciones/Bañosdignos';


import { Daac } from './components/Servicios/estudios-ambientales/Daac';
import { Pama } from './components/Servicios/estudios-ambientales/Pama';
import { Daa } from './components/Servicios/estudios-ambientales/Daa';
import { Iga } from './components/Servicios/estudios-ambientales/Iga';
import { Dia } from './components/Servicios/estudios-ambientales/Dia';
import { Its } from './components/Servicios/estudios-ambientales/Its';
import { Iisc } from './components/Servicios/estudios-ambientales/Iisc';
import { Evap } from './components/Servicios/estudios-ambientales/Evap';
import { Ic } from './components/Servicios/estudios-ambientales/Ic';
import { Dmrs } from './components/Servicios/estudios-ambientales/Dmrs';
import { Sunafil } from './components/Servicios/estudios-ambientales/Sunafil';
import { Test } from './components/Servicios/estudios-ambientales/Test';
import { Eiasd } from './components/Servicios/estudios-ambientales/Eiasd';
import { Eiasd2 } from './components/Servicios/estudios-ambientales/Eiasd2';
import { Geofisicos } from './components/Servicios/estudios-ambientales/Geofisicos';
import { Hidrogeologicos } from './components/Servicios/estudios-ambientales/Hidrogeologicos';
import { Instrumentos } from './components/Servicios/estudios-ambientales/Instrumentos';
import { Participación } from './components/Servicios/estudios-ambientales/Participación';
import { Igafom } from './components/Servicios/estudios-ambientales/Igafom';
import { Actualizacion } from './components/Servicios/estudios-ambientales/Actualizacion';
import { Capacitaciones } from './components/Servicios/estudios-ambientales/Capacitaciones';
import { Mayor } from './components/Servicios/estudios-ambientales/Mayor';
import { Hidrica } from './components/Servicios/estudios-ambientales/Hidrica';



import { Digesa } from './components/Servicios/sanitario/Digesa';
import { Diresa } from './components/Servicios/sanitario/Diresa';

  import { Monitoreoaire } from './components/Servicios/monitoreo/Monitoreoaire';
  import { Monitoreosuelo } from './components/Servicios/monitoreo/Monitoreosuelo';
  import { Monitoreoruido } from './components/Servicios/monitoreo/Monitoreoruido';
    import { Aguasubterraneas } from './components/Servicios/monitoreo/monitoreo-de-agua/Aguasubterraneas';
    import { Aguaresidual } from './components/Servicios/monitoreo/monitoreo-de-agua/Aguaresidual';
    import { Consumohumano } from './components/Servicios/monitoreo/monitoreo-de-agua/Consumohumano';

  import { Monitoreobiologico } from './components/Servicios/monitoreo/Monitoreobiologico';

  import { Clientes } from './components/Clientes';
  import { Mensajeria } from './components/mensajeria/Mensajeria';
  import { Resoluciones } from './components/Resoluciones';
  import { Galeria } from './components/Galeria';
  import { Decreto } from './components/Decreto';


  
import './style.css'


function App() {
 
  

  return (
    
    <div className="App" translate="no">
    
     <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Encabezado/>
        <Routes>
          <Route exact path="*" element={<Error404 />}/>
          <Route exact path="/" element={<Inicio />}/>
          <Route exact path="/inicio" element={<Inicio />}/>
          <Route exact path="/quienes-somos" element={<Quienessomos />}/>
          <Route exact path="/equipo" element={<Equipo />}/>
          <Route exact path="/acreditaciones" element={<Acreditaciones />}/>
          <Route exact path="/tanque-septico" element={<Tanqueseptico />}/>
          <Route exact path="/pozo-precolador" element={<Pozoprecolador />}/>
          <Route exact path="biodigestores" element={<Biodigestores />}/>
          <Route exact path="/baños-dignos" element={<Bañosdignos />}/>

          <Route exact path="/declaracion-ambiental-de-actividades-en-curso" element={<Daac />}/>
          <Route exact path="/programa-de-adecuacion-y-manejo-ambiental" element={<Pama />}/>
          <Route exact path="/declaracion-de-adecuacion-ambiental" element={<Daa />}/>
          <Route exact path="/instrumento-de-gestion-ambientals" element={<Iga />}/>
          <Route exact path="/declaracion-de-impacto-ambiental" element={<Dia />}/>
          <Route exact path="/informe-tecnico-sustentatorio" element={<Its />}/>
          <Route exact path="/informe-de-identificacion-de-sitios-contaminados" element={<Iisc/>}/>
          <Route exact path="/evaluación-ambiental-preliminar" element={<Evap />}/>
          <Route exact path="/informes-de-cumplimiento" element={<Ic/>}/>
          <Route exact path="/declaraciones-de-manejo-de-residuos-solidos" element={<Dmrs/>}/>
          <Route exact path="/informes-de-letrinas" element={<Sunafil />}/>
          <Route exact path="/informe-de-test-de-percolacion" element={<Test />}/>
          <Route exact path="/estudio-de-impacto-ambiental-Semidetallado" element={<Eiasd />}/>
          <Route exact path="/estudio-de-impacto-ambiental" element={<Eiasd2 />}/>
          <Route exact path="/elaboracion-de-estudios-geofisicos" element={<Geofisicos />}/>
          <Route exact path="/elaboracion-de-estudios-hidrogeológicos" element={<Hidrogeologicos />}/>
          <Route exact path="/modificacion-de-instrumentos-ambientales" element={<Instrumentos />}/>
          <Route exact path="/ejecucion-de-tallares-de-participacion-ciudadana" element={<Participación />}/>
          <Route exact path="/instrumentos-de-gestion-ambiental-y-fiscalizacion" element={<Igafom />}/>
          <Route exact path="/actualizacion-de-instrumentos-ambientales" element={<Actualizacion />}/>
          <Route exact path="/capacitaciones-ambientales" element={<Capacitaciones />}/>
          <Route exact path="/uso-de-capacidad-mayor-de-suelo" element={<Mayor />}/>
          <Route exact path="huella-hidrica" element={<Hidrica />}/>

            <Route exact path="/digesa" element={<Digesa/>}/>
            <Route exact path="/diresa" element={<Diresa/>}/>

          <Route exact path="/monitoreo-aire" element={<Monitoreoaire />}/>
           <Route exact path="/monitoreo-suelo" element={<Monitoreosuelo />}/>
           <Route exact path="/monitoreo-ruido" element={<Monitoreoruido />}/>
             <Route exact path="/monitoreo-agua-subterraneas" element={<Aguasubterraneas />}/>
             <Route exact path="/monitoreo-agua-residual" element={<Aguaresidual />}/>
             <Route exact path="/consumo-humano" element={<Consumohumano />}/>
           <Route exact path="/monitoreo-biologico" element={<Monitoreobiologico />}/>

          <Route exact path='/clientes' element={<Clientes/>} />
          <Route exact path='/mensajeria' element={<Mensajeria/>} />
          <Route exact path='/resoluciones' element={<Resoluciones/>} />
          <Route exact path='/galeria' element={<Galeria/>} />
          <Route exact path='/decreto-midagir' element={<Decreto/>} />
        </Routes>
        <Footer/>
        
      </BrowserRouter>
     
    </div>
  );
}

export default App;
